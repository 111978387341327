<script context="module">
  import gql from "graphql-tag";
</script>

<script>
  import { fly, fade, slide } from "svelte/transition";
  import _get from "lodash/get";
  import _filter from "lodash/filter";
  import _find from "lodash/find";

  import MutationInput from "./MutationInput.svelte";
  import Select from "svelte-select";
  import Toggle from "./Toggle.svelte";
  import { tribute } from "../actions/tribute.js";
  import { getTemplateFields } from "../../templateFields";
  import { parsePhoneNumberFromString } from "libphonenumber-js";
  import DatepickerModalForBulkMessaging from "./DatepickerModalForBulkMessaging.svelte";

  export let handleModalClose;
  export let selectedContactIds;
  export let handleBulkMessageSendButtonClick;
  export let handleBulkMessageScheduleButtonClick;
  export let orgData;
  let modalNode;

  let text = "";
  let subject = "";
  let asSms = true;
  let asEmail = true;
  let smsViaLocal = false;

  const SMS_VIA_TOLL_FREE = "SMS_VIA_TOLL_FREE";
  const SMS_VIA_LOCAL = "SMS_VIA_LOCAL";

  let dateValue;
  let outboundSmsNumberOptions;

  $: outboundSmsNumberOptions = _filter(
    [
      {
        number: _get(orgData, "smsTollFree.number"),
        label: `Business SMS Number: ${formatPhoneNumber(
          _get(orgData, "smsTollFree.number")
        )}`,
        value: SMS_VIA_TOLL_FREE,
      },
      {
        number: _get(orgData, "smsLocal.number"),
        label: `Personal SMS Number: ${formatPhoneNumber(
          _get(orgData, "smsLocal.number")
        )}`,
        value: SMS_VIA_LOCAL,
      },
    ],
    "number"
  );

  const modalPaddingPx = 50;

  let templateFields = [];
  $: templateFields = getTemplateFields({
    org: orgData,
  });
  $: tributeConfig = {
    requireLeadingSpace: false,
    values: templateFields,
  };

  function onClickDay(value) {
    dateValue = value;
  }

  async function scheduleMassMessage(dateValue) {}
  $: maxlength = asSms ? 1000 : null;
  let formatPhoneNumber = (number) => {
    number = number || "";
    const parsed =
      parsePhoneNumberFromString(number) ||
      parsePhoneNumberFromString("+1" + number);
    return parsed ? parsed.formatNational() : number;
  };

  function handleEscape(event) {
    if (event.keyCode == 27) {
      handleModalClose();
    }
  }

  let isScheduling = false;
  async function handleSendButtonClick() {
    if (!text) {
      alert("Text field cannot be blank.");
      return;
    } else
      await handleBulkMessageSendButtonClick(
        subject,
        text,
        asSms,
        asEmail,
        smsViaLocal
      );
  }

  async function handleScheduleButtonClickOnScheduleModal() {
    if (!text) {
      alert("Text field cannot be blank.");
      return;
    } else {
      await handleBulkMessageScheduleButtonClick({
        subject: subject,
        text: text,
        asSms: asSms,
        asEmail: asEmail,
        smsViaLocal: smsViaLocal,
        willSendAt: dateValue,
      });

      alert(`This Message will be sent at ${dateValue}.`);
      isScheduling = false;
    }
  }

  function handleScheduleButtonClick() {
    isScheduling = true;
  }

  function handleScheduleModalClose() {
    isScheduling = false;
  }
</script>

<svelte:window on:keydown={handleEscape} />

<div class="modal-container" style="padding:{modalPaddingPx}px;">
  <div class="modal-backdrop" transition:fade={{ duration: 200 }} />
  <div class="modal-close" on:click={handleModalClose}>
    <ion-icon name="close-outline" />
    <span class="keyboard-shortcut"> esc </span>
  </div>
  <div class="modal">
    <div
      class="messaging-modal"
      bind:this={modalNode}
      transition:fly={{ y: 100, duration: 200 }}
    >
      <div class="messaging-header">
        <span
          >Send Message To Selected {selectedContactIds.length} Contacts</span
        >
        <button class="close-btn" on:click={handleModalClose}>×</button>
      </div>
      <div style="padding:20px 30px; background-color: white;">
        <div class="cols">
          <div class="flexcol">
            {#if asEmail}
              <div class="fieldrow">
                <label class="fieldlabel" for="subjectline">
                  Subject Line
                </label>
                <MutationInput
                  type="text"
                  id="subjectline"
                  bind:value={subject}
                  {tributeConfig}
                />
              </div>
            {/if}
            <div class="fieldrow">
              <label class="fieldlabel" for="text"> Message Body </label>
              <textarea
                class="body-entry"
                id="text"
                style="margin-bottom:0;"
                bind:value={text}
                {maxlength}
                use:tribute={tributeConfig}
              />
              {#if asSms}
                <div class="text-length-display">
                  <span>{text.length}/1000</span>
                </div>
              {/if}
            </div>
          </div>
          <div class="fixedcol" style="width:210px; margin-left:20px;">
            <div class="fieldrow merge-fields-menu">
              <div class="fieldlabel">Template Fields</div>
              <div class="fieldhelp">
                Drag & drop into your message, or type <span class="keyboard"
                  >###</span
                >
              </div>
              <ul class="merge-fields">
                {#each templateFields as templateField (templateField.value)}
                  <li>
                    <button
                      type="button"
                      class="chip"
                      on:click|stopPropagation
                      draggable="true"
                      ondragstart="event.dataTransfer.setData('text/plain', '{templateField.value}')"
                    >
                      {templateField.key}
                    </button>
                  </li>
                {/each}
              </ul>
            </div>
          </div>
        </div>
        <div class="smsrow">
          <div class="fieldrow smscol">
            <label class="fieldlabel">
              <Toggle
                bind:checked={asSms}
                on:change={() => {
                  text = asSms ? text.slice(0, 1000) : text;
                }}
              />
              Send via SMS
              <span class="muted">if we have a contact phone number.</span>
            </label>
          </div>
          {#if asSms}
            <div class="fieldrow smscol">
              <div class="fieldlabel">
                <Select
                  items={outboundSmsNumberOptions}
                  noOptionsMessage="No SMS numbers available. Go to Org Settings to request a number!"
                  _optionIdentifier="value"
                  getOptionLabel={(option, filterText) => option.label}
                  getSelectionLabel={(option) => option.label}
                  selectedValue={_find(outboundSmsNumberOptions, {
                    value: smsViaLocal ? SMS_VIA_LOCAL : SMS_VIA_TOLL_FREE,
                  })}
                  on:select={(event) => {
                    console.log(event);
                    smsViaLocal = event.detail.value == SMS_VIA_LOCAL;
                  }}
                  isSearchable={false}
                  isCreatable={false}
                  isClearable={false}
                  isDisabled={false}
                  showIndicator={true}
                />
              </div>
            </div>
          {/if}
        </div>
        <div class="fieldrow">
          <label class="fieldlabel" style="display:inline-block;">
            <Toggle bind:checked={asEmail} />
            Send via Email
            <span class="muted">if we have a contact email address.</span>
          </label>
        </div>
      </div>
      {#if isScheduling}
        <DatepickerModalForBulkMessaging
          {onClickDay}
          handleModalClose={handleScheduleModalClose}
          handleScheduleButtonClick={handleScheduleButtonClickOnScheduleModal}
        />
      {/if}
      <div class="messaging-footer">
        <button on:click={handleScheduleButtonClick}>Schedule send</button>
        <button on:click={handleSendButtonClick}> Send </button>
        <button on:click={handleModalClose}> Cancel </button>
      </div>
    </div>
  </div>
</div>

<style>
  .modal-container {
    background: rgba(0, 0, 0, 0.01);
    position: absolute;
    overflow: auto;
    --webkit-overflow-scrolling: touch;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .modal-backdrop {
    background: var(--offblack-backdrop);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  @supports (backdrop-filter: blur(20px)) {
    .modal-backdrop {
      background: var(--offblack-backdrop-blur);
      backdrop-filter: blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
    }
  }
  .modal-close {
    color: white;
    position: fixed;
    top: 0;
    right: 0;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  .modal-close ion-icon {
    font-size: 48px;
    padding-top: 2px;
  }
  .modal-close .keyboard-shortcut {
    border-radius: 3px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    padding: 0 4px 1px;
    margin-top: -8px;
    opacity: 0.62;
  }
  .modal {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal > :global(*) {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  }
  .messaging-modal {
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 100%;
  }
  .messaging-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--blue);
    color: white;
    border-radius: 8px 8px 0 0;
    font-weight: 600;
  }

  .close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .messaging-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    align-items: center;
    justify-content: center;
  }
  .messaging-container-body {
    display: flex;
    width: 90%;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
  }

  .messaging-footer {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 20px;
    border-radius: 0px 0px 8px 8px;
    background-color: white;
    font-weight: 600;
    gap: 10px;
  }

  .messaging-footer button {
    background-color: var(--blue);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }

  .messaging-footer button:hover {
    background-color: #2e59d9;
  }

  .cols {
    display: flex;
    /* width: 100%; */
    flex-direction: row;
    align-items: flex-start;
  }
  .fixedcol {
    flex-grow: 0;
    flex-shrink: 0;
  }
  .flexcol {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .merge-fields-menu {
    border: 2px solid var(--slate-o10);
    border-radius: 9px;
    padding: 10px;
  }
  .merge-fields-menu .fieldlabel {
    padding-bottom: 4px;
  }
  .merge-fields-menu .fieldhelp {
    font-weight: 600;
    opacity: 0.5;
    padding-bottom: 10px;
    font-size: 12px;
    line-height: 16px;
  }
  .merge-fields-menu ul,
  .merge-fields-menu li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .merge-fields-menu li + li {
    margin-top: 10px;
  }
  .chip {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 15px;
    padding: 0 10px;
    max-width: 100%;
    background: var(--slate-o10);
  }
  .keyboard {
    border-radius: 2px;
    padding: 0 2px;
    display: inline;
    background: var(--slate-o20);
    color: var(--slate);
    /* font-family: monospace; */
    /* font-size: 12px; */
    font-weight: 600;
  }
  .merge-fields-menu button.chip {
    cursor: move;
  }
  textarea.body-entry {
    height: 300px !important;
    width: 100%;
    border: 1px solid #ccc;
    padding: 12px 20px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
    overflow-y: auto;
  }
  .smsrow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .smscol {
    flex-grow: 0;
    flex-shrink: 0;
    /* width: 200px; */
  }
  .smscol + .smscol {
    margin-left: 20px;
    /* flex-grow: 1;
    flex-shrink: 0; */
  }
  .text-length-display {
    display: flex;
    flex-direction: row-reverse;
    padding: 5px 10px;
  }
</style>
