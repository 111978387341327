<script context="module">
  import gql from "graphql-tag";
</script>

<script>
  import { fly, fade, slide } from "svelte/transition";
  import _get from "lodash/get";
  import _filter from "lodash/filter";
  import _find from "lodash/find";

  import MutationInput from "./MutationInput.svelte";
  import Toggle from "./Toggle.svelte";
  import { parsePhoneNumberFromString } from "libphonenumber-js";

  export let handleModalClose;
  export let bulkMessageData;

  let modalNode;

  // const SMS_VIA_TOLL_FREE = "SMS_VIA_TOLL_FREE";
  // const SMS_VIA_LOCAL = "SMS_VIA_LOCAL";

  let dateValue;
  let outboundSmsNumberOptions;

  //   $: outboundSmsNumberOptions = _filter(
  //     [
  //       {
  //         number: _get(orgData, "smsTollFree.number"),
  //         label: `Business SMS Number: ${formatPhoneNumber(
  //           _get(orgData, "smsTollFree.number")
  //         )}`,
  //         value: SMS_VIA_TOLL_FREE,
  //       },
  //       {
  //         number: _get(orgData, "smsLocal.number"),
  //         label: `Personal SMS Number: ${formatPhoneNumber(
  //           _get(orgData, "smsLocal.number")
  //         )}`,
  //         value: SMS_VIA_LOCAL,
  //       },
  //     ],
  //     "number"
  //   );

  const modalPaddingPx = 50;

  let formatPhoneNumber = (number) => {
    number = number || "";
    const parsed =
      parsePhoneNumberFromString(number) ||
      parsePhoneNumberFromString("+1" + number);
    return parsed ? parsed.formatNational() : number;
  };

  function handleEscape(event) {
    if (event.keyCode == 27) {
      handleModalClose();
    }
  }

  function formatDate(dateString) {
    const date = new Date(dateString);

    const options = {
      weekday: "long",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };

    const datePart = date.toLocaleDateString("en-GB", options);
    const timePart = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // 12-hour format with AM/PM
    });

    return `${datePart} at ${timePart}`;
  }
</script>

<svelte:window on:keydown={handleEscape} />

<div class="modal-container" style="padding:{modalPaddingPx}px;">
  <div class="modal-backdrop" transition:fade={{ duration: 200 }} />
  <div class="modal-close" on:click={handleModalClose}>
    <ion-icon name="close-outline" />
    <span class="keyboard-shortcut"> esc </span>
  </div>
  <div class="modal">
    <div
      class="messaging-modal"
      bind:this={modalNode}
      transition:fly={{ y: 100, duration: 200 }}
    >
      <div class="messaging-header">
        <span
          >{bulkMessageData.data.isScheduled && !bulkMessageData.data.fulfilled
            ? "Scheduled"
            : "Sent"} Bulk Message To {bulkMessageData.data.toContacts.length} Contacts
        </span>
        <button class="close-btn" on:click={handleModalClose}>×</button>
      </div>
      <div style="padding:20px 30px; background-color: white;">
        <div class="cols">
          <div class="flexcol">
            {#if bulkMessageData.data.asEmail}
              <div class="fieldrow">
                <label class="fieldlabel" for="subjectline">
                  Subject Line
                </label>
                <MutationInput
                  type="text"
                  id="subjectline"
                  bind:value={bulkMessageData.data.subject}
                  disabled
                />
              </div>
            {/if}
            <div class="fieldrow">
              <label class="fieldlabel" for="text"> Message Body </label>
              <textarea
                class="body-entry"
                id="text"
                style="margin-bottom:0;"
                bind:value={bulkMessageData.data.text}
                disabled
              />
              <div class="text-length-display">
                <span>{bulkMessageData.data.text.length}/1000</span>
              </div>
            </div>
          </div>
        </div>
        <div class="smsrow">
          <div class="fieldrow smscol">
            <label class="fieldlabel">
              <Toggle bind:checked={bulkMessageData.data.asSms} disabled />
              Send via SMS
              <span class="muted">if we have a contact phone number.</span>
            </label>
          </div>
          <!-- {#if asSms}
            <div class="fieldrow smscol">
              <div class="fieldlabel">
                <Select
                  items={outboundSmsNumberOptions}
                  noOptionsMessage="No SMS numbers available. Go to Org Settings to request a number!"
                  _optionIdentifier="value"
                  getOptionLabel={(option, filterText) => option.label}
                  getSelectionLabel={(option) => option.label}
                  selectedValue={_find(outboundSmsNumberOptions, {
                    value: smsViaLocal ? SMS_VIA_LOCAL : SMS_VIA_TOLL_FREE,
                  })}
                  on:select={(event) => {
                    console.log(event);
                    smsViaLocal = event.detail.value == SMS_VIA_LOCAL;
                  }}
                  isSearchable={false}
                  isCreatable={false}
                  isClearable={false}
                  isDisabled={false}
                  showIndicator={true}
                />
              </div>
            </div>
          {/if} -->
        </div>
        <div class="fieldrow">
          <label class="fieldlabel" style="display:inline-block;">
            <Toggle bind:checked={bulkMessageData.data.asEmail} disabled />
            Send via Email
            <span class="muted">if we have a contact email address.</span>
          </label>
        </div>
        <div class="fieldrow">
          {#if bulkMessageData.data.isScheduled && !bulkMessageData.data.fulfilled}
            <strong class="date-prefix">Will Send : </strong><span
              class="date-value"
              >{formatDate(bulkMessageData.data.willSendAt)}</span
            >
          {:else if bulkMessageData.data.isScheduled && bulkMessageData.data.fulfilled}
            <strong class="date-prefix">Sent : </strong><span class="date-value"
              >{formatDate(bulkMessageData.data.willSendAt)}</span
            >
          {:else}
            <strong class="date-prefix">Sent : </strong><span class="date-value"
              >{formatDate(bulkMessageData.data.createdAt)}</span
            >{/if}
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .modal-container {
    background: rgba(0, 0, 0, 0.01);
    position: absolute;
    overflow: auto;
    --webkit-overflow-scrolling: touch;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .modal-backdrop {
    background: var(--offblack-backdrop);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
  @supports (backdrop-filter: blur(20px)) {
    .modal-backdrop {
      background: var(--offblack-backdrop-blur);
      backdrop-filter: blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
    }
  }
  .modal-close {
    color: white;
    position: fixed;
    top: 0;
    right: 0;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    z-index: 1001;
  }
  .modal-close ion-icon {
    font-size: 48px;
    padding-top: 2px;
  }
  .modal-close .keyboard-shortcut {
    border-radius: 3px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    padding: 0 4px 1px;
    margin-top: -8px;
    opacity: 0.62;
  }
  .modal {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal > :global(*) {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  }
  .messaging-modal {
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 100%;
    z-index: 1000;
  }
  .messaging-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--blue);
    color: white;
    border-radius: 8px 8px 0 0;
    font-weight: 600;
  }

  .close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .cols {
    display: flex;
    /* width: 100%; */
    flex-direction: row;
    align-items: flex-start;
  }

  .flexcol {
    flex-grow: 1;
    flex-shrink: 1;
  }

  textarea.body-entry {
    height: 300px !important;
    width: 100%;
    border: 1px solid #ccc;
    padding: 12px 20px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
    overflow-y: auto;
  }
  .smsrow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .smscol {
    flex-grow: 0;
    flex-shrink: 0;
    /* width: 200px; */
  }

  .text-length-display {
    display: flex;
    flex-direction: row-reverse;
    padding: 5px 10px;
  }
</style>
