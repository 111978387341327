<script context="module">
  import gql from "graphql-tag";

  const FRAGMENTS = {};
  const QUERIES = {};
  const MUTATIONS = {};

  export { FRAGMENTS, QUERIES, MUTATIONS };
</script>

<script>
  import _get from "lodash/get";
  import _find from "lodash/find";
  import _trim from "lodash/trim";
  import _map from "lodash/map";
  import _reduce from "lodash/reduce";
  import BulkMessageDetailModal from "./BulkMessageDetailModal.svelte";

  export let bulkMessageData;
  export let handleDeleteBulkMessageClick;

  let isDisplayingDetail = false;
  function formatDate(dateString) {
    const date = new Date(dateString);

    const options = {
      weekday: "long",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };

    const datePart = date.toLocaleDateString("en-GB", options);
    const timePart = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // 12-hour format with AM/PM
    });

    return `${datePart} at ${timePart}`;
  }

  function handleModalClose() {
    isDisplayingDetail = false;
  }
</script>

<div
  class="row"
  style={bulkMessageData.data.isScheduled && !bulkMessageData.data.fulfilled
    ? ""
    : "background-color: #fafafa!important;"}
>
  {#if isDisplayingDetail}
    <BulkMessageDetailModal {bulkMessageData} {handleModalClose} />
  {/if}
  <div class="scheduled-message" on:click={() => (isDisplayingDetail = true)}>
    <div class="message-info">
      <div class="icon">
        {#if bulkMessageData.data.isScheduled && !bulkMessageData.data.fulfilled}
          <ion-icon name="timer-outline"></ion-icon>
        {:else}
          <ion-icon name="checkmark-outline"></ion-icon>
        {/if}
      </div>
      <div class="details">
        <strong
          >{bulkMessageData.data.isScheduled && !bulkMessageData.data.fulfilled
            ? "Scheduled"
            : "Sent"} Bulk {bulkMessageData.data.asEmail ? "Email" : ""}
          {bulkMessageData.data.asEmail && bulkMessageData.data.asSms
            ? "&"
            : ""}
          {bulkMessageData.data.asSms ? "SMS" : ""}</strong
        >
        <div class="schedule">
          {formatDate(
            bulkMessageData.data.willSendAt
              ? bulkMessageData.data.willSendAt
              : bulkMessageData.data.createdAt
          )}
        </div>
        <div class="contacts-count">
          {bulkMessageData.data.toContacts.length} contacts
        </div>
      </div>
    </div>
    <div class="message-content">
      <div class="subject-line">
        <strong>Subject Line:</strong>
        {bulkMessageData.data.subject || "No Subject"}
      </div>
      <div class="message-preview">
        <strong>Message:</strong>
        {_trim(bulkMessageData.data.text).slice(0, 60)}{_trim(
          bulkMessageData.data.text
        ).length > 60
          ? "..."
          : ""}
      </div>
    </div>
    {#if bulkMessageData.data.isScheduled && !bulkMessageData.data.fulfilled}
      <div class="actions">
        <button
          class="button danger"
          on:click={() => handleDeleteBulkMessageClick(bulkMessageData.ref)}
        >
          <ion-icon name="trash-outline"></ion-icon>
          Cancel Bulk Message
        </button>
      </div>
    {/if}
  </div>
</div>

<style>
  .row {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-top: 1px solid #e0e0e0;
    width: 100%;
  }

  .scheduled-message {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .message-info {
    display: flex;
  }

  .icon {
    font-size: 24px;
    padding-top: 10px;
    margin-right: 20px;
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 30px;
    gap: 4px;
    width: 250px;
  }

  .details strong {
    font-size: 14px;
  }

  .schedule {
    font-size: 13px;
  }

  .contacts-count {
    font-size: 13px;
    color: #999;
  }

  .message-content {
    flex: 1;
    padding: 0 20px;
    font-size: 14px;
  }

  .subject-line,
  .message-preview {
    margin-bottom: 4px;
  }

  .actions {
    display: flex;
    align-items: center;
  }

  .button.danger {
    font-size: 14px;
    display: flex;
    gap: 5px;
    color: var(--red);
    font-weight: 600;
  }
</style>
