<script context="module">
  import gql from "graphql-tag";
  import { FRAGMENTS as ContactListItem_FRAGMENTS } from "../../../components/ContactListItem.svelte";

  const FRAGMENTS = {};
  FRAGMENTS.Contacts_contact = gql`
    fragment Contacts_contact on Contact {
      ...ContactListItem_contact
    }
    ${ContactListItem_FRAGMENTS.ContactListItem_contact}
  `;
  FRAGMENTS.CreateContactProfile_contactField = gql`
    fragment CreateContactProfile_contactField on ContactField {
      ref
      data {
        isFirstName
        isLastName
        isEmail
        isSms
      }
    }
  `;
  FRAGMENTS.CreateContactProfile_profileField = gql`
    fragment CreateContactProfile_profileField on ProfileField {
      ref
      data {
        valueJson
        contactField {
          ...CreateContactProfile_contactField
        }
        contact {
          ref
        }
      }
    }
    ${FRAGMENTS.CreateContactProfile_contactField}
  `;
  FRAGMENTS.CreateContactProfile_contactUid = gql`
    fragment CreateContactProfile_contactUid on ContactUid {
      ref
      data {
        value
        contactField {
          ...CreateContactProfile_contactField
        }
      }
    }
    ${FRAGMENTS.CreateContactProfile_contactField}
  `;
  FRAGMENTS.CreateContactProfile_contact = gql`
    fragment CreateContactProfile_contact on Contact {
      ref
      data {
        uids {
          ...CreateContactProfile_contactUid
        }
        profile {
          ...CreateContactProfile_profileField
        }
      }
    }
    ${FRAGMENTS.CreateContactProfile_contactUid}
    ${FRAGMENTS.CreateContactProfile_profileField}
  `;
  const QUERIES = {};
  QUERIES.orgDeletedContactsByFilterOption = gql`
    query orgDeletedContactsByFilterOption(
      $orgId: ID!
      $size: Int!
      $page: Int!
      $filterOption: ContactFilterOptionInput
    ) {
      orgDeletedContactsByFilterOption(
        orgId: $orgId
        size: $size
        page: $page
        filterOption: $filterOption
      ) {
        data {
          ...Contacts_contact
        }
        aggregate {
          count
        }
      }
    }
    ${FRAGMENTS.Contacts_contact}
  `;
  QUERIES.org = gql`
    query org($id: ID!) {
      findOrgByID(id: $id) {
        ref
        data {
          contactFields {
            ref
            data {
              name
              isUid
              isEmail
              isSms
              isFirstName
              isLastName
            }
          }
          contactStatuses {
            ref
            data {
              name
            }
          }
          forms {
            ref
            data {
              title
              name
              isDeleted
            }
          }
          sequences {
            ref
            data {
              name
              isDeleted
            }
          }
        }
      }
    }
  `;
  QUERIES.orgContactsCount = gql`
    query orgContactsCount($orgId: ID!, $sinceDate: Time) {
      orgContactsCount(orgId: $orgId, sinceDate: $sinceDate)
    }
  `;
  QUERIES.getUserByOrg = gql`
    query getUserByOrg($orgId: ID!) {
      getUserByOrg(orgId: $orgId) {
        ref
      }
    }
  `;
  QUERIES.contactFilterOptionByOrg = gql`
    query contactFilterOptionByOrg($orgId: ID!) {
      contactFilterOptionByOrg(orgId: $orgId) {
        ref
        data {
          creationDate {
            name
            from
            to
          }
          submissionDate {
            name
            from
            to
          }
          status {
            id
            name
          }
          actionSequence {
            id
            name
            option
          }
          leadForm {
            id
            name
          }
          size
        }
      }
    }
  `;
  const MUTATIONS = {};
  MUTATIONS.createOrgContact = gql`
    mutation createOrgContact($orgId: ID!) {
      createOrgContact(orgId: $orgId) {
        ref
      }
    }
  `;
  MUTATIONS.createContactUid = gql`
    mutation createContactUid(
      $contactId: ID!
      $contactFieldId: ID!
      $value: String!
    ) {
      createContactUid(
        contactId: $contactId
        contactFieldId: $contactFieldId
        value: $value
      ) {
        ref
      }
    }
  `;
  MUTATIONS.createProfileField = gql`
    mutation createProfileField(
      $contactId: ID!
      $contactFieldId: ID!
      $data: ProfileFieldInput!
    ) {
      createProfileField(
        contactId: $contactId
        contactFieldId: $contactFieldId
        data: $data
      ) {
        ref
      }
    }
  `;
  MUTATIONS.retrieveMassContacts = gql`
    mutation retrieveMassContacts($contactIds: [ID!]) {
      retrieveMassContacts(contactIds: $contactIds) {
        ref
      }
    }
  `;
  MUTATIONS.createOrUpdateContactFilterOptions = gql`
    mutation createOrUpdateContactFilterOptions(
      $orgId: ID!
      $filterOption: ContactFilterOptionInput
      $size: Int
    ) {
      createOrUpdateContactFilterOptions(
        orgId: $orgId
        filterOption: $filterOption
        size: $size
      ) {
        ref
      }
    }
  `;

  export { FRAGMENTS, QUERIES, MUTATIONS };
</script>

<script>
  import { getClient, mutation } from "svelte-apollo";
  import { onMount } from "svelte";
  import _get from "lodash/get";
  import _map from "lodash/map";
  import _concat from "lodash/concat";
  import _isNumber from "lodash/isNumber";
  import _omit from "lodash/omit";
  import _debounce from "lodash/debounce";
  import _slice from "lodash/slice";

  import { watchQuery } from "../../../svelte-apollo-watch-query";

  import HtmlHeadTitle from "../../../components/HtmlHeadTitle.svelte";
  import ContactListItem from "../../../components/ContactListItem.svelte";
  import OrgPageLayout from "../../../components/OrgPageLayout.svelte";
  import CreateContactModal from "../../../components/CreateContactModal.svelte";
  import ContactFilterModal from "../../../components/ContactFilterModal.svelte";
  import { flip } from "svelte/animate";

  export let orgId;
  import Toggle from "../../../components/Toggle.svelte";
  import ContactPagePagination from "../../../components/ContactPagePagination.svelte";
  import ContactPageSizeDropdown from "../../../components/ContactPageSizeDropdown.svelte";
  const client = getClient();

  let showDeleted = true;

  const contactPagePath = `/orgs/${orgId}/contacts`;
  const createContactUidMutation = mutation(MUTATIONS.createContactUid);
  const createProfileFieldMutation = mutation(MUTATIONS.createProfileField);
  const createOrgContactMutation = mutation(MUTATIONS.createOrgContact);
  const retrieveMassContactsMutation = mutation(MUTATIONS.retrieveMassContacts);
  const createOrUpdateContactFilterOptionsMutation = mutation(
    MUTATIONS.createOrUpdateContactFilterOptions
  );

  let getUserByOrg;
  client
    .query({ query: QUERIES.getUserByOrg, variables: { orgId: orgId } })
    .then((result) => {
      getUserByOrg = result.data.getUserByOrg;
    });

  const pageTitle = "Deleted Contacts";

  let filterOption = {
    status: [],
    actionSequence: [],
    leadForm: [],
  };

  let size = 25;
  let page = 1;
  let orgContactsCount = 0;
  let totalPages = 1;
  let cachedOrgContactsCount = 0;

  onMount(async () => {
    try {
      const { data } = await client.query({
        query: QUERIES.contactFilterOptionByOrg,
        variables: { orgId: orgId },
      });
      if (data.contactFilterOptionByOrg) {
        filterOption.creationDate = data.contactFilterOptionByOrg.data
          .creationDate
          ? {
              name: data.contactFilterOptionByOrg.data.creationDate.name,
              from: data.contactFilterOptionByOrg.data.creationDate.from,
              to: data.contactFilterOptionByOrg.data.creationDate.to,
            }
          : null;
        filterOption.submissionDate = data.contactFilterOptionByOrg.data
          .submissionDate
          ? {
              name: data.contactFilterOptionByOrg.data.submissionDate.name,
              from: data.contactFilterOptionByOrg.data.submissionDate.from,
              to: data.contactFilterOptionByOrg.data.submissionDate.to,
            }
          : null;
        let tempStatusFilter = [];
        if (
          data.contactFilterOptionByOrg.data.status &&
          data.contactFilterOptionByOrg.data.status.length > 0
        ) {
          data.contactFilterOptionByOrg.data.status.forEach((element) => {
            tempStatusFilter.push({ id: element.id, name: element.name });
          });
          filterOption.status = tempStatusFilter;
        }
        let tempActionSequenceFilter = [];
        if (
          data.contactFilterOptionByOrg.data.actionSequence &&
          data.contactFilterOptionByOrg.data.actionSequence.length > 0
        ) {
          data.contactFilterOptionByOrg.data.actionSequence.forEach(
            (element) => {
              tempActionSequenceFilter.push({
                id: element.id,
                name: element.name,
                option: element.option,
              });
            }
          );
          filterOption.actionSequence = tempActionSequenceFilter;
        }
        let tempLeadFormFilter = [];
        if (
          data.contactFilterOptionByOrg.data.leadForm &&
          data.contactFilterOptionByOrg.data.leadForm.length > 0
        ) {
          data.contactFilterOptionByOrg.data.leadForm.forEach((element) => {
            tempLeadFormFilter.push({ id: element.id, name: element.name });
          });
          filterOption.leadForm = tempLeadFormFilter;
        }
        size = data.contactFilterOptionByOrg.data.size
          ? data.contactFilterOptionByOrg.data.size
          : 25;
      }
    } catch (error) {
      console.error(error);
    }
  });

  const orgQuery = watchQuery(client, {
    query: QUERIES.org,
    variables: { id: orgId },
    pollInterval: 300e3, // 5 minutes
  });
  $: orgData = _get($orgQuery, "data.findOrgByID.data", {});
  $: orgContactFields = _get(orgData, "contactFields", []);
  $: filteredContactQuery = watchQuery(client, {
    query: QUERIES.orgDeletedContactsByFilterOption,
    variables: {
      orgId: orgId,
      size: size,
      page: page,
      filterOption: filterOption,
    },
    pollInterval: 0,
  });

  async function fetchContacts() {
    isLoading = true;
    isError = null;
    try {
      const { data } = await client.query({
        query: QUERIES.orgDeletedContactsByFilterOption,
        variables: {
          orgId,
          size,
          page,
          filterOption: filterOption,
        },
        fetchPolicy: "network-only", // Ensure fresh data is fetched
      });
      orgContacts = _get(data, "orgDeletedContactsByFilterOption.data", []);
      orgContactsCount = _get(
        data,
        "orgDeletedContactsByFilterOption.aggregate.count",
        0
      );
      if (orgContactsCount !== cachedOrgContactsCount) {
        orgContactsCount = orgContactsCount;
        cachedOrgContactsCount = orgContactsCount; // Update the cached count
        totalPages = Math.ceil(orgContactsCount / size);
      }
    } catch (err) {
      isError = err.message;
    } finally {
      isLoading = false;
    }
  }

  let isError = false;

  $: isLoading = $filteredContactQuery.loading;

  $: orgContacts = _get(
    $filteredContactQuery,
    "data.orgDeletedContactsByFilterOption.data",
    []
  );

  $: orgContactsCount = _get(
    $filteredContactQuery,
    "data.orgDeletedContactsByFilterOption.aggregate.count",
    0
  );

  $: if ($filteredContactQuery && orgContactsCount !== undefined) {
    // Check if orgContactsCount has been updated and if query has data
    if (orgContactsCount !== cachedOrgContactsCount && orgContactsCount > 0) {
      cachedOrgContactsCount = orgContactsCount; // Update the cached count
      totalPages = Math.ceil(cachedOrgContactsCount / size); // Update total pages
    }
  }

  // $: totalPages =
  //   orgContactsCount % size == 0
  //     ? orgContactsCount / size
  //     : Math.floor(orgContactsCount / size) + 1;
  let isCreatingContact = false;

  function handleModalClose() {
    isCreatingContact = false;
    firstName = "";
    lastName = "";
    emailAddress = "";
    phoneNumber = "";
  }

  function handleModalOpen() {
    isCreatingContact = true;
  }

  let isSelectingFilterOption = false;

  function handleFilterModalOpen() {
    isSelectingFilterOption = true;
  }

  // $: filterOption = {};
  function handleFilterModalClose() {
    isSelectingFilterOption = false;
    // filterOption = {};
  }

  let firstName = "";
  let lastName = "";
  let emailAddress = "";
  let phoneNumber = "";

  function handleInputChange(event) {
    switch (event.target.name) {
      case "firstName":
        firstName = event.target.value;
        break;
      case "lastName":
        lastName = event.target.value;
        break;
      case "emailAddress":
        emailAddress = event.target.value;
        break;
      case "phoneNumber":
        phoneNumber = event.target.value;
        break;
      default:
        break;
    }
  }

  async function createContactUid(contactId, contactFieldId, value) {
    try {
      let response = await createContactUidMutation({
        variables: {
          contactId: contactId,
          contactFieldId: contactFieldId,
          value: value,
        },
      });
      orgData = _get($orgQuery, "data.findOrgByID.data", {});
    } catch (error) {
      console.log(error);
    }
  }
  async function createProfileField(contactId, contactFieldId, data) {
    try {
      let response = await createProfileFieldMutation({
        variables: {
          contactId: contactId,
          contactFieldId,
          data,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function createContact() {
    // if (isCreatingContact) return;
    // isCreatingContact = true;

    try {
      const totalContactCount = orgContactsCount;
      // Check if the total contact count exceeds 100,000
      if (totalContactCount >= 100000) {
        alert(
          "You have reached the contact limit. No more contacts can be added."
        );
        isCreatingContact = false;
        return totalContactCount;
      }
      let data = {
        firstName,
        lastName,
        emailAddress,
        phoneNumber,
      };
      if (
        data.firstName === "" &&
        data.lastName === "" &&
        data.emailAddress === "" &&
        data.phoneNumber === ""
      ) {
        alert(
          "A contact cannot be created if all fields are empty. Please fill in one or more fields."
        );
        return;
      }

      // let response = await mutate(client, {
      //     mutation: MUTATIONS.createOrgContact,
      //     variables: {
      //         orgId: orgId,
      //     },
      //     refetchQueries: ['orgContactsByCreatedAt'],
      // });
      let response = await createOrgContactMutation({
        variables: {
          orgId: orgId,
        },
      });

      let contactId = response.data.createOrgContact.ref;
      // console.log(response);
      if (data.emailAddress != "") {
        const contactFieldForEmail = orgContactFields.filter(
          (contactField) =>
            contactField.data.isUid == true && contactField.data.isEmail == true
        );
        const contactFieldIdForEmail = contactFieldForEmail[0].ref;
        await createContactUid(
          contactId,
          contactFieldIdForEmail,
          data.emailAddress
        );
      }
      if (data.phoneNumber != "") {
        const contactFieldForPhone = orgContactFields.filter(
          (contactField) =>
            contactField.data.isUid == true && contactField.data.isSms == true
        );
        const contactFieldIdForPhone = contactFieldForPhone[0].ref;
        await createContactUid(
          contactId,
          contactFieldIdForPhone,
          data.phoneNumber
        );
      }
      if (data.firstName != "") {
        const contactFieldForFirstName = orgContactFields.filter(
          (contactField) =>
            !contactField.data.isUid && contactField.data.isFirstName == true
        );
        let profileFieldData = {
          org: orgId,
          updatedBy: getUserByOrg.ref,
          valueJson: JSON.stringify(data.firstName),
        };
        const contactFieldIfForFirstName = contactFieldForFirstName[0].ref;
        await createProfileField(
          contactId,
          contactFieldIfForFirstName,
          profileFieldData
        );
      }
      if (data.lastName != "") {
        const contactFieldForLastName = orgContactFields.filter(
          (contactField) =>
            !contactField.data.isUid && contactField.data.isLastName == true
        );
        let profileFieldData = {
          org: orgId,
          updatedBy: getUserByOrg.ref,
          valueJson: JSON.stringify(data.lastName),
        };
        const contactFieldIfForLastName = contactFieldForLastName[0].ref;
        await createProfileField(
          contactId,
          contactFieldIfForLastName,
          profileFieldData
        );
      }

      handleModalClose();
    } catch (error) {
      // FIXME handle errors better...
      alert(error.message);
    }
    // isCreatingContact = false;
  }

  let selectedContactIds = [];
  let isRetrieving = false;

  // function selectContacts(contactId, checkboxStatus) {
  //   if (checkboxStatus) selectedContactIds.push(contactId);
  //   else
  //     selectedContactIds = selectedContactIds.filter((id) => id != contactId);
  // }

  //   function handleClickRetrieveButton() {
  //     isRetrieving = true;
  //   }

  async function handleClickRetrieveButton() {
    if (selectedContactIds.length == 0) {
      alert("There are no contacts selected to retive!");
      return;
    }

    if (
      confirm(
        "The selected contacts will be retrived. Are you sure you want to proceed?"
      )
    ) {
      try {
        const response = await retrieveMassContactsMutation({
          variables: {
            contactIds: selectedContactIds,
          },
        });
        // let tempOrgContacts = orgContacts;
        orgContacts = orgContacts.filter(
          (contact) => !selectedContactIds.includes(contact.ref)
        );
        // orgContactsCount -= selectedContactIds.length;
      } catch (error) {
        console.log(error);
        alert(error);
      }
      isRetrieving = false;
      selectedContactIds = [];
    } else {
      return;
    }
  }

  //   function handleCancelButtonClick() {
  //     isRetrieving = false;
  //     selectedContactIds = [];
  //   }

  async function createOrUpdateContactFilterOptions(orgId, filterOption, size) {
    const result = await createOrUpdateContactFilterOptionsMutation({
      variables: {
        orgId,
        filterOption,
        size,
      },
    });
    return result;
  }
  async function handleSubmitFilterOptions(data) {
    page = 1;
    selectedContactIds = [];
    filterOption = data;
    isSelectingFilterOption = false;
    await createOrUpdateContactFilterOptions(orgId, filterOption, size);
    await fetchContacts();
  }

  async function handleFilterOptionCancelClick(filterName, id) {
    if (filterName == "creationDate") {
      filterOption.creationDate.name = null;
      filterOption.creationDate.from = null;
      filterOption.creationDate.to = null;
    } else if (filterName == "submissionDate") {
      filterOption.submissionDate.name = null;
      filterOption.submissionDate.from = null;
      filterOption.submissionDate.to = null;
    } else {
      filterOption[filterName] = filterOption[filterName].filter(
        (item) => item.id != id
      );
    }
    page = 1;
    await createOrUpdateContactFilterOptions(orgId, filterOption, size);
    await fetchContacts();
  }

  async function handleChangeCurrentPage(value) {
    page = value;
    selectedContactIds = [];
    await fetchContacts();
  }

  async function handleChangeDropDown(value) {
    size = value;
    selectedContactIds = [];
    await createOrUpdateContactFilterOptions(orgId, filterOption, size);
    await fetchContacts();
  }

  function handleContactSelectionCheckBox() {
    if (
      selectedContactIds.length < size &&
      selectedContactIds.length < orgContactsCount
    ) {
      orgContacts.forEach((element) => {
        if (!selectedContactIds.includes(element.ref)) {
          selectedContactIds = [...selectedContactIds, element.ref];
        }
      });
    } else {
      selectedContactIds = [];
    }
  }
</script>

<HtmlHeadTitle {pageTitle} />
{#if isCreatingContact}
  <CreateContactModal {isCreatingContact} {handleModalClose}>
    <div class="sidebar-content">
      <h1>Create Contact</h1>
      <section class="section">
        <div class="field-section">
          <h3 class="field-name">Contact Name</h3>
          <div class="name-row">
            <div class="name-col">
              <label>First name</label>
              <input
                type="text"
                placeholder="Bruce"
                value={firstName}
                name="firstName"
                on:input={handleInputChange}
              />
            </div>
            <div class="name-col">
              <label>Last name</label>
              <input
                type="text"
                placeholder="Wayne"
                name="lastName"
                value={lastName}
                on:input={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div class="field-section">
          <h3 class="field-name">Email Addresses</h3>
          <input
            type="email"
            placeholder="name@example.com"
            name="emailAddress"
            value={emailAddress}
            on:input={handleInputChange}
          />
        </div>
        <div class="field-section">
          <h3 class="field-name">Phone Numbers (SMS)</h3>
          <input
            type="tel"
            value={phoneNumber}
            name="phoneNumber"
            placeholder="555-321-1111"
            on:input={handleInputChange}
          />
        </div>
      </section>
      <div class="controll-button">
        <button
          type="button"
          on:click|stopPropagation={createContact}
          class="button"
        >
          <!-- class:muted={isCreatingContact}
                      disabled={isCreatingContact} -->
          <ion-icon name="add" class="btn-icon" />
          <span class="btn-text"> Create Contact </span>
        </button>
      </div>
    </div>
  </CreateContactModal>
{:else if isSelectingFilterOption}
  <ContactFilterModal
    handleModalClose={handleFilterModalClose}
    {orgData}
    {filterOption}
    {handleSubmitFilterOptions}
  ></ContactFilterModal>
{:else}
  <OrgPageLayout {orgId} title={pageTitle}>
    <div class="listgroup-header-bar">
      <div class="listgroup-head-left">
        <span class="chip">
          {_isNumber(orgContactsCount) ? orgContactsCount : "loading"}
          contacts
        </span>
      </div>
      <div class="listgroup-head-right">
        <button
          type="button"
          on:click|stopPropagation={handleModalOpen}
          class="button"
          class:muted={isCreatingContact}
          disabled={isCreatingContact}
        >
          <ion-icon name="add" class="btn-icon" />
          <span class="btn-text"> Add a New Contact </span>
        </button>
        <a
          href={contactPagePath}
          class="filter-deleted button"
          style="width: 200px!important;">View All Contacts</a
        >
      </div>
    </div>
    <section class="card listgroup">
      <header class="listgroup-head">
        <button
          type="button"
          on:click|stopPropagation={handleFilterModalOpen}
          class="button"
          class:muted={isSelectingFilterOption}
          disabled={isSelectingFilterOption}
        >
          <ion-icon name="funnel-outline" class="btn-icon" />
          <span class="btn-text"> Filter Option </span>
        </button>

        <span class="chip"> newest first </span>

        {#if _get(filterOption, "creationDate", "") && _get(filterOption, "creationDate.name", "")}
          {#if _get(filterOption, "creationDate.name", "") !== "Custom date range"}
            <span class="filter-option-display"
              >Creation Date :&nbsp;<span style="font-weight: 700"
                >{_get(filterOption, "creationDate.name")}
              </span><ion-icon
                on:click={() => {
                  handleFilterOptionCancelClick("creationDate", "");
                }}
                class="filter-option-display-close-btn"
                name="close-outline"
              /></span
            >
          {:else}
            <span class="filter-option-display"
              >Creation Date : <span style="font-weight: 700"
                >{_get(filterOption, "creationDate.from", "")} ~ {_get(
                  filterOption,
                  "creationDate.to",
                  ""
                )}</span
              >
              <ion-icon
                on:click={() => {
                  handleFilterOptionCancelClick("creationDate", "");
                }}
                class="filter-option-display-close-btn"
                name="close-outline"
              /></span
            >
          {/if}
        {/if}
        {#if _get(filterOption, "submissionDate", "") && _get(filterOption, "submissionDate.name", "")}
          {#if _get(filterOption, "submissionDate.name", "") !== "Custom date range"}
            <span class="filter-option-display"
              >Submission Date :&nbsp;<span style="font-weight: 700"
                >{_get(filterOption, "submissionDate.name")}
              </span><ion-icon
                on:click={() => {
                  handleFilterOptionCancelClick("submissionDate", "");
                }}
                class="filter-option-display-close-btn"
                name="close-outline"
              /></span
            >
          {:else}
            <span class="filter-option-display"
              >Submission Date : &nbsp;<span style="font-weight: 700"
                >{_get(filterOption, "submissionDate.from", "")} ~ {_get(
                  filterOption,
                  "submissionDate.to",
                  ""
                )}</span
              >
              <ion-icon
                on:click={() => {
                  handleFilterOptionCancelClick("submissionDate", "");
                }}
                class="filter-option-display-close-btn"
                name="close-outline"
              /></span
            >
          {/if}
        {/if}
        {#if _get(filterOption, "status", []).length > 0}
          {#each filterOption.status as statusFilterOption}
            <span class="filter-option-display"
              >Status :&nbsp; <span style="font-weight: 700"
                >{statusFilterOption.name}</span
              ><ion-icon
                on:click={() => {
                  handleFilterOptionCancelClick(
                    "status",
                    statusFilterOption.id
                  );
                }}
                class="filter-option-display-close-btn"
                name="close-outline"
              /></span
            >
          {/each}{/if}
        {#if _get(filterOption, "actionSequence", []).length > 0}
          {#each filterOption.actionSequence as actionSequenceFilterOption}
            <span class="filter-option-display"
              >Action Sequence :&nbsp; <span style="font-weight: 700"
                >{actionSequenceFilterOption.name}({actionSequenceFilterOption.option})</span
              ><ion-icon
                on:click={() => {
                  handleFilterOptionCancelClick(
                    "actionSequence",
                    actionSequenceFilterOption.id
                  );
                }}
                class="filter-option-display-close-btn"
                name="close-outline"
              /></span
            >
          {/each}{/if}
        {#if _get(filterOption, "leadForm", []).length > 0}
          {#each filterOption.leadForm as leadFormFilterOption}
            <span class="filter-option-display"
              >Submit :&nbsp; <span style="font-weight: 700"
                >{leadFormFilterOption.name}</span
              ><ion-icon
                on:click={() => {
                  handleFilterOptionCancelClick(
                    "leadForm",
                    leadFormFilterOption.id
                  );
                }}
                class="filter-option-display-close-btn"
                name="close-outline"
              /></span
            >
          {/each}
        {/if}
      </header>
      <div class="listgroup-checkbox-layer">
        <button
          class="contact-check-box-status"
          on:click={handleContactSelectionCheckBox}
          >{#if selectedContactIds.length == size || (selectedContactIds.length && selectedContactIds.length == orgContactsCount)}<ion-icon
              name="checkmark-outline"

            ></ion-icon>{:else if selectedContactIds.length > 0 && selectedContactIds.length < size}<ion-icon
              name="remove-outline"
            ></ion-icon>{/if}</button
        >
        {#if selectedContactIds.length > 0}<button
            type="button"
            class="button danger"
            class:muted={isCreatingContact}
            disabled={isCreatingContact}
            on:click|stopPropagation={handleClickRetrieveButton}
          >
            <ion-icon name="reload-outline" class="btn-icon" />
            <span class="btn-text"> Restore Contacts </span>
          </button>{/if}
      </div>
      <ul>
        {#if isLoading && !orgContacts.length}
          <li class="load-more-content">
            <div class="spinner" />
          </li>
        {:else if isError}
          <li>
            errors: <pre>{JSON.stringify(
                $filteredContactQuery.errors,
                null,
                4
              )}</pre>
          </li>
        {:else if orgContacts.length}
          {#each orgContacts as data (data.ref)}
            <li animate:flip={{ duration: 500 }} class="contact-list-item">
              <div class="contact-checkbox">
                <input
                  type="checkbox"
                  id={data.ref}
                  checked={selectedContactIds.includes(data.ref)}
                  on:change={() => {
                    if (selectedContactIds.includes(data.ref)) {
                      // Filter out the unselected contact and reassign the array
                      selectedContactIds = selectedContactIds.filter(
                        (item) => item !== data.ref
                      );
                    } else {
                      // Add the selected contact and reassign the array
                      selectedContactIds = [...selectedContactIds, data.ref];
                    }

                    console.log("Selected IDs => ", selectedContactIds);
                  }}
                />
              </div>

              <ContactListItem contactData={data} />
              <!-- {isRetrieving} -->
            </li>
          {/each}
        {:else}
          <li>
            <div class="empty-message">
              <div class="iconcol">
                <ion-icon name="skull" />
              </div>
              <div class="textcol">
                <div class="text-primary">No contacts yet!</div>
                <!-- <div class="text-secondary">
                    Submit one of your lead forms on your website to see a contact
                    appear here.
                    <br />
                    Or
                    <a href={`/orgs/${orgId}/forms`}
                      >create your first lead form</a
                    > if you haven't already.
                  </div> -->
              </div>
            </div>
          </li>
        {/if}
      </ul>
    </section>

    <div class="contact-page-footer">
      <div class="contact-page-size-drop-down">
        <ContactPageSizeDropdown
          {handleChangeDropDown}
          {size}
          disable={isLoading}
        />
      </div>
      <div class="contact-pagination">
        <ContactPagePagination
          {handleChangeCurrentPage}
          {totalPages}
          currentPage={page}
          disabled={isLoading}
        />
      </div>
    </div>
  </OrgPageLayout>
{/if}

<style>
  .listgroup {
    margin: 0 20px;
  }
  .listgroup-head {
    display: flex;
    /* flex-direction: row; */
    flex-wrap: wrap;
    align-items: center;
    padding: 10px 15px;
    gap: 10px;
  }
  .listgroup li {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
  .listgroup-head-left {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 12px 15px;
  }
  .listgroup-head-right {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 12px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .listgroup-head-right .button {
    height: 30px;
    width: 180px;
    margin: 0 10px 0 10px;
  }
  .listgroup-checkbox-layer .button {
    height: 30px;
    width: 180px;
  }
  .listgroup-head-right .button ion-icon {
    --ionicon-stroke-width: 48px;
  }

  .listgroup-head .button {
    height: 30px;
    padding: 5px 15px;
    margin: 5px 0 5px 0;
  }
  .listgroup-head .button ion-icon {
    --ionicon-stroke-width: 48px;
  }

  .chip {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 15px;
    padding: 0 10px;
    background: var(--offwhite);
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .chip + .chip {
    margin-left: 5px;
  }
  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .card {
    background: var(--white);
    border: 1px solid var(--offoffwhite);
    border-radius: 9px;
  }
  .empty-message {
    margin: 20px;
    padding: 20px;
    background: var(--lightblue);
    color: var(--blue);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-around; */
    width: 100%;
    box-sizing: border-box;
  }
  .empty-message .iconcol {
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 20px;
    font-size: 48px;
    margin-right: 10px;
  }
  .empty-message .textcol {
    flex-grow: 1;
    flex-shrink: 0;
  }
  .empty-message .text-primary {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
  }
  .empty-message .text-secondary {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    opacity: 0.8;
  }
  .load-more-content {
    display: flex;
    justify-content: center;
    margin: 5px;
    /* border-top: 1px solid var(--offwhite); */
  }
  .spinner {
    width: 40px;
    height: 40px;
    margin: 100px auto;
    background-color: #000;

    border-radius: 100%;
    animation: sk-scaleout 1s infinite ease-in-out;
  }
  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }
  .sidebar-content {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: #ffffff;
    color: var(--offblack);
    border-radius: 4px;
    padding: 50px;
    /* overflow: hidden; */
  }
  .section {
    padding: 30px 20px 45px;
  }
  .field-name {
    font-size: 18px;
    line-height: 1.4;
    font-weight: 600;
    margin: 0 0 10px;
    padding: 0;
  }
  .field-section + .field-section {
    margin-top: 20px;
    border-top: 1px solid var(--slate-o10);
    padding-top: 20px;
  }
  .name-row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
  .name-col {
    flex-grow: 1;
    flex-shrink: 0;
    width: 200px;
  }
  .name-col + .name-col {
    margin-left: 20px;
  }
  .button.danger {
    color: var(--red);
  }

  .button.cancel {
    color: var(--blue);
  }
  .filter-deleted {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5px;
  }
  .filter-deleted .text {
    margin-right: 6px;
  }

  .listgroup-header-bar {
    display: flex;
    padding: 5px 20px;
    justify-content: space-between;
  }
  .filter-option-display {
    display: flex;
    align-items: center;
    /* vertical-align: middle; */
    font-size: 14px;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 15px;
    padding: 0 5px 0px 10px;
    background: var(--offwhite);
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .filter-option-display-close-btn {
    font-size: 20px;
    border-radius: 50%;
    background-color: white;
    margin-left: 5px;
  }
  .contact-page-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 30px 30px;
  }

  .contact-page-size-drop-down {
    position: absolute;
    left: 0;
  }
  .listgroup-checkbox-layer {
    display: flex;
    flex-grow: 1;
    background-color: var(--offwhite);
    padding-left: 20px;
    gap: 20px;
    align-items: center;
    height: 50px;
  }

  .contact-check-box-status {
    background-color: white;
    width: 20px;
    height: 20px;
    border: 1px solid black;
    border-radius: 6px;
  }
  .contact-checkbox {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    width: 40px;
    justify-content: center;
    height: 100%;
    align-items: center;
  }
  input[type="checkbox"] {
    width: 15px;
    height: 15px;
    border-radius: 4px;
    border: 1px solid var(--offwhite);
  }
  .contact-list-item {
    border-top: 1px solid var(--offwhite);
    display: flex;
    align-items: center !important;
    padding: 5px 10px;
  }
</style>
