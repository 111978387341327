<script context="module">
  import gql from "graphql-tag";
  import { FRAGMENTS as MessageListItem_FRAGMENTS } from "./MessageListItem.svelte";

  const FRAGMENTS = {};
  FRAGMENTS.ContactMessages_contact = gql`
    fragment ContactMessages_contact on Contact {
      _id
      # profile {
      #     data {
      #         _id
      #         valueJson
      #         contactField {
      #             _id
      #             isFirstName
      #             isLastName
      #         }
      #     }
      # }
    }
  `;
  FRAGMENTS.ContactMessages_messageRequest = gql`
    fragment ContactMessages_messageRequest on MessageRequest {
      ...MessageListItem_messageRequest
    }
    ${MessageListItem_FRAGMENTS.MessageListItem_messageRequest}
  `;

  const QUERIES = {};
  QUERIES.contactMessages = gql`
    query contactMessages($contactId: ID!, $size: Int!) {
      contactMessages(contactId: $contactId, size: $size) {
        page {
          before
          after
          data {
            node {
              ...ContactMessages_messageRequest
            }
          }
        }
        # aggregate {
        #     count
        # }
      }
    }
    ${FRAGMENTS.ContactMessages_messageRequest}
  `;

  const MUTATIONS = {};

  MUTATIONS.retrieveContact = gql`
    mutation retrieveContact($contactId: ID!) {
      retrieveContact(contactId: $contactId) {
        ref
      }
    }
  `;

  export { FRAGMENTS, QUERIES, MUTATIONS };
</script>

<script>
  // import { getContext } from 'svelte';
  import _get from "lodash/get";
  import _find from "lodash/find";
  import _trim from "lodash/trim";
  import _map from "lodash/map";
  import _merge from "lodash/merge";
  import _cloneDeep from "lodash/cloneDeep";
  import { navigate } from "svelte-routing";
  import moment from "moment";
  import { getClient, mutation } from "svelte-apollo";
  import { watchQuery } from "../svelte-apollo-watch-query";
  import { createMessageRequest } from "../api";
  import { autoheight } from "../actions/autoheight.js";
  import MessageListItem from "./MessageListItem.svelte";
  import { modalPaddingPx } from "./Modal.svelte";
  import DeleteStatusBox from "./DeleteStatusBox.svelte";

  export let contactId;
  export let contactData;
  export let sidebarHeightPx = 0;
  export let headerHeightPx = 0;

  // $: contactPath = `/contacts/${contactId}`;

  const client = getClient();
  const contactMessagesQuery = watchQuery(client, {
    query: QUERIES.contactMessages,
    variables: {
      contactId: contactId,
      size: 200,
    },
    pollInterval: 15000,
  });
  $: messages = _map(
    _get($contactMessagesQuery, "data.contactMessages.page.data", []),
    "node"
  ).reverse();
  // $: messagesCount = _get($contactMessagesQuery, 'data.contactMessages.aggregate.count');

  let scrollContainer;
  // const scrollTo = (top) => {
  //     // console.log('scrollTo', top);
  //     scrollContainer && scrollContainer.scrollTo({top: top, behavior: 'smooth'});
  // }
  // let initialScrollComplete = false;
  // function initScroll() {
  //     if (!messages.length) return;
  //     if (initialScrollComplete) return;
  //     initialScrollComplete = true;
  //     requestAnimationFrame(() => scrollTo(999999));
  // }
  // $: messages, initScroll();

  // const getProfile = (contact, where) => {
  //     let valueJson = _get(
  //         _find(_get(contact, 'profile.data', []), {contactField: where}),
  //         'valueJson'
  //     );
  //     return valueJson ? JSON.parse(valueJson) : undefined;
  // };
  //
  // $: profileFirstName = getProfile(contactData, {isFirstName: true}) || '';
  // $: profileLastName = getProfile(contactData, {isLastName: true}) || '';
  // $: nameDisplay = _trim(`${profileFirstName} ${profileLastName}`);

  const retrieveContactMutation = mutation(MUTATIONS.retrieveContact);

  let windowHeightPx;
  let containerHeightPx;
  let paddingBottomPx = 20;
  let containerHeightMin = 200;
  let footerHeightPx;
  $: modalHeightPx = Math.max(
    sidebarHeightPx,
    windowHeightPx - 2 * modalPaddingPx
  );
  $: containerHeightPx = Math.max(
    containerHeightMin,
    modalHeightPx - headerHeightPx - paddingBottomPx - footerHeightPx
  );
  // let lastContainerHeightPx = containerHeightPx;
  // let maintainScrollTimeout;
  // function maintainScroll() {
  //     if (scrollContainer) {
  //         cancelAnimationFrame(maintainScrollTimeout);
  //         maintainScrollTimeout = requestAnimationFrame(() => {
  //             let containerHeightDelta = containerHeightPx - lastContainerHeightPx;
  //             containerHeightDelta && scrollTo(scrollContainer.scrollTop - containerHeightDelta);
  //             lastContainerHeightPx = containerHeightPx;
  //         })
  //     }
  // }
  // $: containerHeightPx, maintainScroll();

  // $: isOutgoing = !!_get(latestMessage, 'toContact._id');
  // $: isAutomated = !(
  //     _get(latestMessage, 'triggeredByUser') ||
  //     _get(latestMessage, 'triggeredByInboundSms') ||
  //     _get(latestMessage, 'triggeredByInboundEmail')
  // );
  let newMessageText = "";
  let sendingMessage = false;
  let newMessageTextarea;
  async function handleSubmitNewMessage() {
    if (sendingMessage) return;
    sendingMessage = true;
    let data = {
      toContactId: contactId,
      subject: "",
      text: newMessageText,
    };
    try {
      let response = await createMessageRequest(data);
      let responseData = await response.json();
      if (responseData.resText != "") {
        alert(responseData.resText);
      }
      if (response.ok) {
        // let messageRequestData = await response.json();
        // messages = messages.concat([messageRequestData]);
        // Reset so that when the new message appears, we scroll to the bottom.
        // initialScrollComplete = false;
        await $contactMessagesQuery.observableQuery.refetch();
        newMessageText = "";
        setTimeout(() => {
          newMessageTextarea && newMessageTextarea.focus();
        });
      } else {
        throw new Error("Failed");
      }
    } catch (error) {
      alert("Error sending the message! Please try again, or contact support.");
    }
    sendingMessage = false;
  }

  async function handleClickRetrieveButton(contactId) {
    try {
      const response = await retrieveContactMutation({
        variables: {
          contactId: contactId,
        },
      });
      let tempContactData = contactData;
      tempContactData.data.isDeleted = false;
      tempContactData.data.deletedAt = null;
      contactData = tempContactData;
    } catch (error) {
      console.log(error);
      alert(error);
    }
  }
</script>

<svelte:window bind:innerHeight={windowHeightPx} />

{#if !_get(contactData, "data.isDeleted", false)}
  <div class="footer-container">
    <div
      bind:this={scrollContainer}
      class="scroll-container"
      style="height:{containerHeightPx}px; padding-bottom:{paddingBottomPx}px;"
    >
      <div class="scroll-container-unreverse">
        <ol>
          {#each messages as message, i (message.ref)}
            <li>
              <MessageListItem
                messageData={message}
                prevMessageData={i >= 0 ? messages[i - 1] : null}
              />
            </li>
          {/each}
        </ol>
      </div>
    </div>
    <form
      class="compose-message"
      on:submit|preventDefault={handleSubmitNewMessage}
      bind:offsetHeight={footerHeightPx}
    >
      <div class="textarea-with-button">
        <textarea
          autofocus
          placeholder="Type a message"
          bind:value={newMessageText}
          use:autoheight
          bind:this={newMessageTextarea}
        ></textarea>
        <button
          type="submit"
          class="button primary raised icon-only"
          disabled={sendingMessage}
          class:muted={sendingMessage}
        >
          <!-- <ion-icon name="paper-plane" /> -->
          <ion-icon name="arrow-up" />
        </button>
      </div>
    </form>
  </div>
{:else}
<p class="disabled-alert">
  <span
    >This contact has been deleted and is set to permanently delete within 30
    days.</span
  >
  <span
    >To modify or message this contact you must first restore the contact.</span
  >
</p>
  <div class="deleted-info">
    <DeleteStatusBox deletedAt={contactData.data.deletedAt} />

    <button
      type="button"
      on:click|stopPropagation={() =>
        handleClickRetrieveButton(contactData.ref)}
      class="button"
    >
      <ion-icon name="reload-outline" class="btn-icon" />
      <span class="btn-text"> Restore </span>
    </button>
  </div>
{/if}

<style>
  .footer-container {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    /* flex-grow: 1;
    flex-shrink: 1; */
  }
  .scroll-container {
    min-height: 100px;
    overflow: auto;
    --webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column-reverse;
    /* overscroll-behavior: {containerHeightPx == containerHeightMin ? 'contain' : 'auto'}; */
    /* position: relative;
    z-index: 1; */
  }
  .scroll-container-unreverse {
    display: flex;
    flex-direction: column-reverse;
  }
  ol,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  form.compose-message {
    /* position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    right: 0; */
    /* background: var(--white-backdrop); */
    /* border-radius: inherit; */
    padding: 3px 20px 10px;
    /* position: relative;
    z-index: 1; */
  }
  /* @supports (backdrop-filter: blur(20px)) {
    form.compose-message {
        background: var(--white-backdrop-blur);
        backdrop-filter: blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
    }
} */
  .textarea-with-button {
    padding: 0;
    margin: 0;
    position: relative;
  }
  .textarea-with-button textarea {
    display: block;
    width: 100%;
    /* font-size: 16px; */
    /* border-radius: 21px; /* natural height is 42px; * / */
    padding-right: 57px;
    resize: none; /* We are trying out a new auto-size feature. Please contact us if this is painful for you. */
  }
  .textarea-with-button .button {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    background: var(--blue);
    color: #fff; /* Intentionally not var(--white) so that it doesn't flip in dark mode. */
    margin: 5px;
    box-shadow: none;
  }
  .button.icon-only {
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    padding: 0;
  }
  .button.icon-only ion-icon {
    flex-shrink: 0;
    /* position: relative; left: -1px; top: 1px; /* The paper-plane icon looks off-center when it is centered based on its box. */
    font-size: 24px;
  }
  .disabled-alert {
    padding: 0px 30px;
    color: red;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .deleted-info {
    display: flex;
    padding-left: 30px;
    align-items: center;
  }
  .deleted-style {
    display: flex;
    flex-direction: column;
    width: 115px;
    height: 45px;
    padding: 4px 15px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 5px;
    color: #d65543;
    background: rgba(214, 67, 67, 0.12);
  }
  .status-text {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px 0px 2px 0px;
  }
  .date-text {
    text-decoration: none !important;
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  .status-icon {
    width: 15px;
    height: 15px;
    margin-right: 4px;
  }
  .button {
    margin: 0px 0px 0px 20px;
    padding: 10px 15px !important;
  }
  .button:hover {
    color: var(--blue);
  }
  .btn-text {
    font-size: 16px !important;
  }
</style>
