<script context="module">
</script>

<script>
  import { fly, fade } from "svelte/transition";
  import _map from "lodash/map";
  import _range from "lodash/range";

  import Datepicker from "./Datepicker.svelte";
  import Select from "svelte-select";

  let modalNode;
  export let dateValue = new Date();
  export let handleModalClose;
  export let handleScheduleButtonClick;
  export let onClickDay;

  // Helper functions to format hours and minutes as strings
  let hourNumberToString = (n) => (n > 12 ? n - 12 : n).toString();
  let minuteNumberToString = (n) => (n < 10 ? "0" : "") + n.toString();

  // Initialize date and time

  // Set initial values for hour, minute, and AM/PM
  let hour = hourNumberToString(dateValue.getHours() % 12 || 12);
  let minute = minuteNumberToString(dateValue.getMinutes());
  let ampm = dateValue.getHours() >= 12 ? "PM" : "AM";

  let minDate = new Date();
  let maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 1);
  const modalPaddingPx = 50;

  function handleEscape(event) {
    if (event.keyCode == 27) {
      handleModalClose();
    }
  }

  // Function to update the dateValue based on selected hour, minute, and AM/PM
  function updateDateTime() {
    let selectedHour = parseInt(hour);
    if (ampm === "PM" && selectedHour < 12) {
      selectedHour += 12;
    } else if (ampm === "AM" && selectedHour === 12) {
      selectedHour = 0;
    }

    dateValue.setHours(selectedHour);
    dateValue.setMinutes(parseInt(minute));
    onClickDay(dateValue);
  }

  // Event handler to handle date selection changes
  function handleDatePickerChange(date) {
    dateValue = date;
    updateDateTime();
  }

  // Options for hour, minute, and AM/PM dropdowns
  const hourOptions = _map(_range(1, 13), hourNumberToString);
  const minuteOptions = _map(_range(60), minuteNumberToString);
  const amPmOptions = ["AM", "PM"];
</script>

<svelte:window on:keydown={handleEscape} />

<div class="modal-container" style="padding:{modalPaddingPx}px;">
  <div class="modal-backdrop" transition:fade={{ duration: 200 }} />
  <div class="modal-close" on:click={handleModalClose}>
    <ion-icon name="close-outline" />
    <span class="keyboard-shortcut"> esc </span>
  </div>
  <div class="modal">
    <div
      class="scheduling-modal"
      bind:this={modalNode}
      transition:fly={{ y: 100, duration: 200 }}
    >
      <div class="scheduling-header">
        <span>Schedule Time for Messaging</span>
        <button class="close-btn" on:click={handleModalClose}>×</button>
      </div>
      <div class="scheduling-container">
        <Datepicker
          {minDate}
          {maxDate}
          value={dateValue}
          defaultDate={new Date()}
          handleChangeDatePicker={handleDatePickerChange}
        />
        <div class="dateAndTimeInput">
          <div class="fixedcol hour">
            <Select
              items={hourOptions}
              selectedValue={hour}
              isCreatable={false}
              isClearable={false}
              on:select={(event) => {
                hour = event.detail.value;
                updateDateTime();
              }}
              showIndicator={true}
            ></Select>
          </div>
          <div class="fixedcol" style="padding:0 1px;">
            <strong>:</strong>
          </div>
          <div class="fixedcol minute">
            <Select
              items={minuteOptions}
              selectedValue={minute}
              isCreatable={false}
              isClearable={false}
              on:select={(event) => {
                minute = event.detail.value;
                updateDateTime();
              }}
              showIndicator={true}
            ></Select>
          </div>
          <div class="fixedcol ampm" style="margin-left:6px;">
            <Select
              items={amPmOptions}
              selectedValue={ampm}
              isCreatable={false}
              isClearable={false}
              on:select={(event) => {
                ampm = event.detail.value;
                updateDateTime();
              }}
              showIndicator={true}
            ></Select>
          </div>
        </div>
      </div>

      <div class="scheduling-footer">
        <button on:click={handleScheduleButtonClick}> Schedule </button>
        <button on:click={handleModalClose}> Cancel </button>
      </div>
    </div>
  </div>
</div>

<style>
  .modal-container {
    background: rgba(0, 0, 0, 0.01);
    position: absolute;
    overflow: auto;
    --webkit-overflow-scrolling: touch;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
  }
  .modal-backdrop {
    background: var(--offblack-backdrop);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  @supports (backdrop-filter: blur(20px)) {
    .modal-backdrop {
      background: var(--offblack-backdrop-blur);
      backdrop-filter: blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
    }
  }
  .modal-close {
    color: white;
    position: fixed;
    top: 0;
    right: 0;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  .modal-close ion-icon {
    font-size: 48px;
    padding-top: 2px;
  }
  .modal-close .keyboard-shortcut {
    border-radius: 3px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    padding: 0 4px 1px;
    margin-top: -8px;
    opacity: 0.62;
  }
  .modal {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal > :global(*) {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  }
  .scheduling-modal {
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 50%;
    max-width: 100%;
  }
  .scheduling-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--blue);
    color: white;
    border-radius: 8px 8px 0 0;
    font-weight: 600;
  }

  .close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .scheduling-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: white;
    justify-content: center;
    padding-top: 20px;
    background-color: #fefefe;
  }

  .scheduling-footer {
    display: flex;
    justify-content: center;
    gap: 15px;
    text-align: center;
    padding: 20px;
    border-radius: 0px 0px 8px 8px;
    background-color: white;
    font-weight: 600;
    background-color: #fefefe;
  }

  .scheduling-footer button {
    background-color: var(--blue);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }

  .scheduling-footer button:hover {
    background-color: #2e59d9;
  }
  .dateAndTimeInput {
    display: flex;
    flex-direction: row;
    padding: 20px;
    gap: 15px;
    justify-content: center;
    align-items: center;
  }
</style>
