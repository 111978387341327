<svelte:options accessors />

<script context="module">
  import gql from "graphql-tag";

  const FRAGMENTS = {};
  FRAGMENTS.EditMessageTemplate_org = gql`
    fragment EditMessageTemplate_org on OrgData {
      smsLocal {
        number
      }
      smsTollFree {
        number
      }
    }
  `;
  FRAGMENTS.EditMessageTemplate_messageTemplateDraft = gql`
    fragment EditMessageTemplate_messageTemplateDraft on MessageTemplateDraft {
      ref
      data {
        body
        subjectLine
        email
        sms
        smsViaLocal
      }
    }
  `;
  FRAGMENTS.EditMessageTemplate_messageTemplate = gql`
    fragment EditMessageTemplate_messageTemplate on MessageTemplate {
      ref
      data {
        body
        subjectLine
        email
        sms
        smsViaLocal
        draft {
          ...EditMessageTemplate_messageTemplateDraft
        }
        org {
          ref
          data {
            name
            ...EditMessageTemplate_org
          }
        }
      }
    }
    ${FRAGMENTS.EditMessageTemplate_messageTemplateDraft}
    ${FRAGMENTS.EditMessageTemplate_org}
  `;

  const QUERIES = {};
  // QUERIES.sequence = gql`
  //     query sequence($id: ID!) {
  //         findSequenceByID(id: $id) {
  //             ...editSeqActsSequence
  //         }
  //     }
  //     ${FRAGMENTS.editSeqActsSequence}
  // `;

  const MUTATIONS = {};
  MUTATIONS.updateMessageTemplate = gql`
    mutation updateMessageTemplate($id: ID!) {
      updateMessageTemplate(id: $id) {
        ...EditMessageTemplate_messageTemplate
      }
    }
    ${FRAGMENTS.EditMessageTemplate_messageTemplate}
  `;
  MUTATIONS.updateMessageTemplateDraft = gql`
    mutation updateMessageTemplateDraft(
      $id: ID!
      $data: PartialUpdateMessageTemplateDraftInput!
    ) {
      partialUpdateMessageTemplateDraft(id: $id, data: $data) {
        ...EditMessageTemplate_messageTemplateDraft
      }
    }
    ${FRAGMENTS.EditMessageTemplate_messageTemplateDraft}
  `;
  MUTATIONS.publishMessageTemplateDraft = gql`
    mutation publishMessageTemplateDraft($messageTemplateId: ID!) {
      publishMessageTemplateDraft(messageTemplateId: $messageTemplateId) {
        ...EditMessageTemplate_messageTemplate
      }
    }
    ${FRAGMENTS.EditMessageTemplate_messageTemplate}
  `;
  MUTATIONS.deleteMessageTemplateDraft = gql`
    mutation deleteMessageTemplateDraft($id: ID!) {
      deleteMessageTemplateDraft(id: $id) {
        ref
      }
    }
  `;

  export { FRAGMENTS, QUERIES, MUTATIONS };
</script>

<script>
  import { createEventDispatcher } from "svelte";
  import { getClient, mutation } from "svelte-apollo";
  import Select from "svelte-select";
  import moment from "moment";
  import _get from "lodash/get";
  import _has from "lodash/has";
  import _debounce from "lodash/debounce";
  import _keys from "lodash/keys";
  import _pick from "lodash/pick";
  import _filter from "lodash/filter";
  import _find from "lodash/find";
  import Toggle from "./Toggle.svelte";
  import MutationInput from "./MutationInput.svelte";
  import { autoheight } from "../actions/autoheight.js";
  import { tribute } from "../actions/tribute.js";
  import { getTemplateFields } from "../../templateFields";
  import { parsePhoneNumberFromString } from "libphonenumber-js";

  const client = getClient();
  const updateMessageTemplateMutation = mutation(
    MUTATIONS.updateMessageTemplate
  );
  const updateMessageTemplateDraftMutation = mutation(
    MUTATIONS.updateMessageTemplateDraft
  );
  const publishMessageTemplateDraftMutation = mutation(
    MUTATIONS.publishMessageTemplateDraft
  );
  const deleteMessageTemplateDraftMutation = mutation(
    MUTATIONS.deleteMessageTemplateDraft
  );
  const dispatch = createEventDispatcher();

  export let messageTemplateData = {};
  export let orgData;
  export let formStatus;
  export const UNSAVED = {};
  export const SAVED = {};
  export const SAVING = {};
  $: messageTemplateDraft = _get(messageTemplateData, "data.draft") || {};
  let bodyEntry = "";
  let subjectLineEntry = "";
  let emailEntry = true;
  let smsEntry = true;
  let smsViaLocalEntry = false;

  // Init form data when it loads (from the network).
  let hasInitBody = false;
  let hasInitSubjectLine = false;
  let hasInitEmail = false;
  let hasInitSms = false;
  let hasInitSmsViaLocal = false;
  function initFormFields() {
    if (!_get(messageTemplateDraft, "ref")) return;
    if (_has(messageTemplateDraft, "data.body") && !hasInitBody) {
      bodyEntry = messageTemplateDraft.data.body;
      hasInitBody = true;
    }
    if (_has(messageTemplateDraft, "data.subjectLine") && !hasInitSubjectLine) {
      subjectLineEntry = messageTemplateDraft.data.subjectLine;
      hasInitSubjectLine = true;
    }
    if (_has(messageTemplateDraft, "data.email") && !hasInitEmail) {
      emailEntry = messageTemplateDraft.data.email;
      hasInitEmail = true;
    }
    if (_has(messageTemplateDraft, "data.sms") && !hasInitSms) {
      smsEntry = messageTemplateDraft.data.sms;
      hasInitSms = true;
    }
    if (
      !hasInitSmsViaLocal &&
      // hack here because smsViaLocal is optional (missing is interpreted as false),
      // so we assume, if sms has loaded, that smsViaLocal has also loaded and is missing.
      (_has(messageTemplateDraft, "data.smsViaLocal") ||
        _has(messageTemplateDraft, "data.sms"))
    ) {
      smsViaLocalEntry = !!messageTemplateDraft.data.smsViaLocal;
      hasInitSmsViaLocal = true;
    }
  }
  $: messageTemplateDraft, initFormFields(), messageTemplateData;

  let bodySaving = null;
  let subjectLineSaving = null;
  let emailSaving = null;
  let smsSaving = null;
  let smsViaLocalSaving = null;

  // Treat falsey values as equal.
  const entriesEqual = (a, b) => (a || "") == (b || "");
  $: formStatus =
    !entriesEqual(
      bodyEntry,
      bodySaving === null ? _get(messageTemplateDraft, "data.body") : bodySaving
    ) ||
    !entriesEqual(
      subjectLineEntry,
      subjectLineSaving === null
        ? _get(messageTemplateDraft, "data.subjectLine")
        : subjectLineSaving
    ) ||
    !entriesEqual(
      emailEntry,
      emailSaving === null
        ? _get(messageTemplateDraft, "data.email")
        : emailSaving
    ) ||
    !entriesEqual(
      smsEntry,
      smsSaving === null ? _get(messageTemplateDraft, "data.sms") : smsSaving
    ) ||
    !entriesEqual(
      smsViaLocalEntry,
      smsViaLocalSaving === null
        ? !!_get(messageTemplateDraft, "data.smsViaLocal")
        : smsViaLocalSaving
    ) ||
    !bodyEntry.trim()
      ? UNSAVED
      : entriesEqual(bodyEntry, _get(messageTemplateDraft, "data.body")) &&
          entriesEqual(
            subjectLineEntry,
            _get(messageTemplateDraft, "data.subjectLine")
          ) &&
          entriesEqual(emailEntry, _get(messageTemplateDraft, "data.email")) &&
          entriesEqual(smsEntry, _get(messageTemplateDraft, "data.sms")) &&
          entriesEqual(
            smsViaLocalEntry,
            !!_get(messageTemplateDraft, "data.smsViaLocal")
          )
        ? SAVED
        : SAVING;
  // $: console.log('formStatus', formStatus == UNSAVED ? 'UNSAVED' : (formStatus == SAVED ? 'SAVED' : 'SAVING'));

  // Ensure the message template has a draft.
  $: if (
    _get(messageTemplateData, "ref") &&
    !_get(messageTemplateDraft, "ref")
  ) {
    createDraft();
  }

  let savingData;
  export async function saveForm() {
    if (formStatus != UNSAVED) return;
    if (!bodyEntry.trim()) {
      alert(
        "The message body cannot be empty. Remember to fill the Message body."
      );
      return;
    }
    if (bodyEntry.length > 1000) {
      alert(
        "SMS messages must be under 1,000 characters. Reduce your message to 1,000 characters or disable SMS for this message to exceed 1,000 characters."
      );
      return;
    }

    let updates = {};
    savingData = updates;

    let optimisticBody =
      bodySaving === null
        ? _get(messageTemplateDraft, "data.body", "")
        : bodySaving;
    let bodyDirty = hasInitBody && bodyEntry != optimisticBody;
    if (bodyDirty) updates.body = bodyEntry;
    bodySaving = bodyDirty ? bodyEntry : null;

    let optimisticSubjectLine =
      subjectLineSaving === null
        ? _get(messageTemplateDraft, "data.subjectLine", "")
        : subjectLineSaving;
    let subjectLineDirty =
      hasInitSubjectLine && subjectLineEntry != optimisticSubjectLine;
    if (subjectLineDirty) updates.subjectLine = subjectLineEntry;
    subjectLineSaving = subjectLineDirty ? subjectLineEntry : null;

    let optimisticEmail =
      emailSaving === null
        ? _get(messageTemplateDraft, "data.email", "")
        : emailSaving;
    let emailDirty = hasInitEmail && emailEntry != optimisticEmail;
    if (emailDirty) updates.email = emailEntry;
    emailSaving = emailDirty ? emailEntry : null;

    let optimisticSms =
      smsSaving === null
        ? _get(messageTemplateDraft, "data.sms", "")
        : smsSaving;
    let smsDirty = hasInitSms && smsEntry != optimisticSms;
    if (smsDirty) updates.sms = smsEntry;
    smsSaving = smsDirty ? smsEntry : null;

    let optimisticSmsViaLocal =
      smsViaLocalSaving === null
        ? !!_get(messageTemplateDraft, "data.smsViaLocal", "")
        : smsViaLocalSaving;
    let smsViaLocalDirty =
      hasInitSmsViaLocal && smsViaLocalEntry != optimisticSmsViaLocal;
    if (smsViaLocalDirty) updates.smsViaLocal = smsViaLocalEntry;
    smsViaLocalSaving = smsViaLocalDirty ? smsViaLocalEntry : null;

    let maybeClearSavingState = () => {
      if (savingData === updates) {
        bodySaving = null;
        subjectLineSaving = null;
        emailSaving = null;
        smsSaving = null;
        smsViaLocalSaving = null;
        savingData = null;
      }
    };

    if (!_keys(updates).length) {
      maybeClearSavingState();
      return;
    }

    await updateMessageTemplateDraft(
      _get(messageTemplateDraft, "ref", ""),
      updates
    );

    maybeClearSavingState();
    // dispatch('save');
  }

  // const saveFormDebounced = _debounce(saveForm, 1000);
  // $: if (formStatus == UNSAVED && _has(messageTemplateDraft, '_id'))
  //     saveFormDebounced();

  async function updateMessageTemplate(id) {
    try {
      // let response = await mutate(client, {
      //     mutation: MUTATIONS.updateMessageTemplate,
      //     variables: {
      //         id: id,
      //         // data: data,
      //     },
      // });
      const response = await updateMessageTemplateMutation({
        variables: {
          id: id,
        },
      });
      client.writeFragment({
        id: id,
        fragment: FRAGMENTS.EditMessageTemplate_messageTemplate,
        fragmentName: "EditMessageTemplate_messageTemplate",
        data: response.data.partialUpdateMessageTemplate,
      });

      // messageTemplateData = response.data.updateMessageTemplate;
      messageTemplateDraft = response.data.updateMessageTemplate.data.draft;
    } catch (error) {
      // FIXME handle errors better...
      console.log(error);
      alert(error.message);
    }
  }
  async function updateMessageTemplateDraft(id, data) {
    try {
      // let response = await mutate(client, {
      //     mutation: MUTATIONS.updateMessageTemplateDraft,
      //     variables: {
      //         id: id,
      //         data: data,
      //     },
      // });
      let response = await updateMessageTemplateDraftMutation({
        variables: {
          id: id,
          data: data,
        },
      });
      // messageTemplateDraft =
      //     response.data.partialUpdateMessageTemplateDraft;
      client.writeFragment({
        id: id,
        fragment: FRAGMENTS.EditMessageTemplate_messageTemplateDraft,
        fragmentName: "EditMessageTemplate_messageTemplateDraft",
        data: response.data.partialUpdateMessageTemplateDraft,
      });
    } catch (error) {
      // FIXME handle errors better...
      alert(error.message);
    }
  }
  async function publishMessageTemplateDraft(messageTemplateId) {
    await saveForm();
    try {
      // let response = await mutate(client, {
      //     mutation: MUTATIONS.publishMessageTemplateDraft,
      //     variables: {
      //         messageTemplateId: messageTemplateId,
      //     },
      // });
      let response = await publishMessageTemplateDraftMutation({
        variables: {
          messageTemplateId: messageTemplateId,
        },
      });
      client.writeFragment({
        id: messageTemplateId,
        fragment: FRAGMENTS.EditMessageTemplate_messageTemplate,
        fragmentName: "EditMessageTemplate_messageTemplate",
        data: response.data.publishMessageTemplateDraft,
      });
      return response.data.publishMessageTemplateDraft;
    } catch (error) {
      // FIXME handle errors better...
      alert(error.message);
    }
  }
  async function deleteMessageTemplateDraft(id) {
    try {
      // let response = await mutate(client, {
      //     mutation: MUTATIONS.deleteMessageTemplateDraft,
      //     variables: {
      //         id: id,
      //     },
      // });
      let response = await deleteMessageTemplateDraftMutation({
        variables: {
          id: id,
        },
      });
      // FIXME Need cache.evict() and cache.gc() (coming in apollo-client v3)
      // messageTemplateData.data.draft = null;
      client.writeFragment({
        id: messageTemplateData.ref,
        fragment: FRAGMENTS.EditMessageTemplate_messageTemplate,
        fragmentName: "EditMessageTemplate_messageTemplate",
        data: messageTemplateData,
      });
    } catch (error) {
      // FIXME handle errors better...
      alert(error.message);
    }
  }

  let creatingDraft = false;
  async function createDraft() {
    if (creatingDraft) return;
    creatingDraft = true;
    await updateMessageTemplate(messageTemplateData.ref);
    creatingDraft = false;
  }
  let publishingDraft = false;
  export async function publishDraft() {
    if (publishingDraft) return;
    publishingDraft = true;
    const result = await publishMessageTemplateDraft(messageTemplateData.ref);
    dispatch("publishDraft");
    publishingDraft = false;
    return result;
  }
  let deletingDraft = false;
  export async function deleteDraft() {
    if (deletingDraft) return;
    deletingDraft = true;
    hasInitBody = false;
    hasInitSubjectLine = false;
    hasInitEmail = false;
    hasInitSms = false;
    hasInitSmsViaLocal = false;
    await deleteMessageTemplateDraft(messageTemplateDraft.ref);
    dispatch("deleteDraft");
    deletingDraft = false;
  }

  const exampleYear = moment().add(1, "month").format("YYYY");
  let templateFields = [];
  $: templateFields = getTemplateFields({
    org: _get(messageTemplateData, "data.org"),
  });
  $: tributeConfig = {
    requireLeadingSpace: false,
    values: templateFields,
  };

  // We would typically assign an object as a unique value, but that doesn't play nicely
  // with svelte-select <Select> because they JSON.stringify() the values before comparing them.
  const SMS_VIA_TOLL_FREE = "SMS_VIA_TOLL_FREE";
  const SMS_VIA_LOCAL = "SMS_VIA_LOCAL";
  let outboundSmsNumberOptions;
  let formatPhoneNumber = (number) => {
    number = number || "";
    const parsed =
      parsePhoneNumberFromString(number) ||
      parsePhoneNumberFromString("+1" + number);
    return parsed ? parsed.formatNational() : number;
  };
  $: outboundSmsNumberOptions = _filter(
    [
      {
        number: _get(orgData, "data.smsTollFree.number"),
        label: `Business SMS Number: ${formatPhoneNumber(
          _get(orgData, "data.smsTollFree.number")
        )}`,
        value: SMS_VIA_TOLL_FREE,
      },
      {
        number: _get(orgData, "data.smsLocal.number"),
        label: `Personal SMS Number: ${formatPhoneNumber(
          _get(orgData, "data.smsLocal.number")
        )}`,
        value: SMS_VIA_LOCAL,
      },
    ],
    "number"
  );

  // const htmlTagRegex = /(<([^>]+)>)/ig;
  // $: if (htmlTagRegex.test(bodyEntry)) {
  //     bodyEntry = bodyEntry.replace(htmlTagRegex, '');
  // }
  // <div class="faketextarea" contenteditable="true" id="bodyEntry-{_get(messageTemplateDraft,'_id')}"
  //     bind:innerHTML={bodyEntry}
  //     use:autoheight use:tribute={{values: templateFields}}
  // ></div>
</script>

<div style="padding-top:20px;" class:muted={!_get(messageTemplateDraft, "ref")}>
  <div class="cols">
    <div class="flexcol">
      {#if emailEntry}
        <div class="fieldrow">
          <label
            class="fieldlabel"
            for="subjectLineEntry-{_get(messageTemplateDraft, 'ref')}"
          >
            Subject Line
          </label>
          <MutationInput
            type="text"
            id="subjectLineEntry-{_get(messageTemplateDraft, 'ref')}"
            placeholder={_get(messageTemplateDraft, "ref")
              ? `Our Pricing for ${exampleYear}`
              : "Loading..."}
            bind:value={subjectLineEntry}
            savingValue={subjectLineSaving}
            savedValue={_get(messageTemplateDraft, "data.subjectLine")}
            {tributeConfig}
          />
        </div>
      {/if}
      <div class="fieldrow">
        <label
          class="fieldlabel"
          for="bodyEntry-{_get(messageTemplateDraft, 'ref')}"
        >
          Message Body
        </label>
        <textarea
          class="body-entry"
          id="bodyEntry-{_get(messageTemplateDraft, 'ref')}"
          style="margin-bottom:0;"
          bind:value={bodyEntry}
          use:autoheight
          use:tribute={tributeConfig}
        />
        {#if smsEntry}
          <div
            class={bodyEntry.length <= 1000
              ? "text-length-display"
              : "text-length-display warning"}
          >
            {bodyEntry.length}/1000
          </div>
        {/if}
      </div>
    </div>
    <div class="fixedcol" style="width:210px; margin-left:20px;">
      <div class="fieldrow merge-fields-menu">
        <div class="fieldlabel">Template Fields</div>
        <div class="fieldhelp">
          Drag & drop into your message, or type <span class="keyboard"
            >###</span
          >
        </div>
        <ul class="merge-fields">
          {#each templateFields as templateField (templateField.value)}
            <li>
              <button
                type="button"
                class="chip"
                on:click|stopPropagation
                draggable="true"
                ondragstart="event.dataTransfer.setData('text/plain', '{templateField.value}')"
              >
                {templateField.key}
              </button>
            </li>
          {/each}
        </ul>
      </div>
    </div>
  </div>
  <div class="smsrow">
    <div class="fieldrow smscol">
      <label class="fieldlabel">
        <Toggle bind:checked={smsEntry} />
        Send via SMS
        <span class="muted">if we have a contact phone number.</span>
      </label>
    </div>
    <div class="fieldrow smscol">
      <div class="fieldlabel">
        <Select
          items={outboundSmsNumberOptions}
          noOptionsMessage="No SMS numbers available. Go to Org Settings to request a number!"
          _optionIdentifier="value"
          getOptionLabel={(option, filterText) => option.label}
          getSelectionLabel={(option) => option.label}
          selectedValue={_find(outboundSmsNumberOptions, {
            value: smsViaLocalEntry ? SMS_VIA_LOCAL : SMS_VIA_TOLL_FREE,
          })}
          on:select={(event) => {
            console.log(event);
            smsViaLocalEntry = event.detail.value == SMS_VIA_LOCAL;
          }}
          isSearchable={false}
          isCreatable={false}
          isClearable={false}
          isDisabled={false}
          showIndicator={true}
        />
      </div>
    </div>
  </div>
  <div class="fieldrow">
    <label class="fieldlabel" style="display:inline-block;">
      <Toggle bind:checked={emailEntry} />
      Send via Email
      <span class="muted">if we have a contact email address.</span>
    </label>
  </div>
</div>

<style>
  .cols {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
  }
  .fixedcol {
    flex-grow: 0;
    flex-shrink: 0;
  }
  .flexcol {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .merge-fields-menu {
    border: 2px solid var(--slate-o10);
    border-radius: 9px;
    padding: 10px;
  }
  .merge-fields-menu .fieldlabel {
    padding-bottom: 4px;
  }
  .merge-fields-menu .fieldhelp {
    font-weight: 600;
    opacity: 0.5;
    padding-bottom: 10px;
    font-size: 12px;
    line-height: 16px;
  }
  .merge-fields-menu ul,
  .merge-fields-menu li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .merge-fields-menu li + li {
    margin-top: 10px;
  }
  .chip {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 15px;
    padding: 0 10px;
    max-width: 100%;
    background: var(--slate-o10);
  }
  .keyboard {
    border-radius: 2px;
    padding: 0 2px;
    display: inline;
    background: var(--slate-o20);
    color: var(--slate);
    /* font-family: monospace; */
    /* font-size: 12px; */
    font-weight: 600;
  }
  .merge-fields-menu button.chip {
    cursor: move;
  }
  textarea.body-entry {
    resize: none; /* We are trying out a new auto-size feature. Please contact us if this is painful for you. */
  }
  .smsrow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .smscol {
    flex-grow: 0;
    flex-shrink: 0;
    /* width: 200px; */
  }
  .smscol + .smscol {
    margin-left: 20px;
    /* flex-grow: 1;
    flex-shrink: 0; */
  }
  .text-length-display {
    display: flex;
    flex-direction: row-reverse;
    padding: 5px;
  }
  .warning {
    color: var(--red);
  }
</style>
