<script context="module">
  import gql from "graphql-tag";
  import { FRAGMENTS as ContactStatusDropdown_FRAGMENTS } from "./ContactStatusDropdown.svelte";

  const FRAGMENTS = {};
  FRAGMENTS.ContactListItem_sequenceAction = gql`
    fragment ContactListItem_sequenceAction on SequenceAction {
      ref
      data {
        type
        delay {
          minDelay
          minDelayUnits
          allowedDays
          allowedStartTime {
            hour
            minute
            pm
          }
          allowedEndTime {
            hour
            minute
            pm
          }
        }
      }
    }
  `;
  FRAGMENTS.ContactListItem_contact = gql`
    fragment ContactListItem_contact on Contact {
      ref
      data {
        org {
          ref
          data {
            timeZone
            contactStatuses {
              ...ContactStatusDropdown_contactStatus
            }
          }
        }
        sequences {
          ref
          data {
            createdAt
            lastActionAt
            isPaused
            isCompleted
            nextActions {
              node {
                ...ContactListItem_sequenceAction
              }
            }
            sequence {
              ref
              data {
                name
              }
            }
          }
        }
        uids {
          ref
          data {
            value
            contactField {
              ref
              data {
                isEmail
                isSms
              }
            }
          }
        }
        profile {
          ref
          data {
            valueJson
            contactField {
              ref
              data {
                isFirstName
                isLastName
              }
            }
          }
        }
        status {
          ref
          data {
            name
            color
          }
        }
        isDeleted
        deletedAt
      }
    }
    ${ContactStatusDropdown_FRAGMENTS.ContactStatusDropdown_contactStatus}
    ${FRAGMENTS.ContactListItem_sequenceAction}
  `;

  const QUERIES = {};
  const MUTATIONS = {};
  MUTATIONS.retrieveContact = gql`
    mutation retrieveContact($contactId: ID!) {
      retrieveContact(contactId: $contactId) {
        ref
      }
    }
  `;

  export { FRAGMENTS, QUERIES, MUTATIONS };
</script>

<script>
  import { getClient, query, mutation } from "svelte-apollo";
  import _get from "lodash/get";
  import _find from "lodash/find";
  import _trim from "lodash/trim";
  import _map from "lodash/map";
  import _reduce from "lodash/reduce";
  import { navigate } from "svelte-routing";
  import moment from "moment";
  import { parsePhoneNumberFromString } from "libphonenumber-js";
  import { computeNextDelayCompletionAfter } from "../../sequenceDelay";
  import ContactStatusDropdown from "./ContactStatusDropdown.svelte";
  import DeleteStatusBox from "./DeleteStatusBox.svelte";
  export let contactData;
  // export let selectContacts;
  // export let isDeletingContacts;

  const client = getClient();

  const retrieveContactMutation = mutation(MUTATIONS.retrieveContact);

  $: contactPath = `/contacts/${contactData.ref}`;

  let isContactStatusDropdownOpen = false;

  let currentContactSequence;
  $: currentContactSequence = _find(
    _get(contactData, "data.sequences", []),
    (contactSequence) => !_get(contactSequence, "data.isCompleted", false)
  );

  function computeNextNonDelayActionAndWhen(
    nextSequenceActions,
    lastActionMoment
  ) {
    return _reduce(
      nextSequenceActions,
      (accumulator, sequenceAction) => {
        // Once we've determined the next non-delay action, we ignore everything else.
        if (accumulator.nextAction) return accumulator;

        if ("DELAY" == sequenceAction.data.type) {
          accumulator.when = computeNextDelayCompletionAfter(
            sequenceAction.data.delay,
            accumulator.when,
            _get(contactData, "data.org.data.timeZone")
          );
        } else {
          accumulator.nextAction = sequenceAction;
        }

        return accumulator;
      },
      {
        when: lastActionMoment,
        nextAction: null,
      }
    );
  }
  $: lastActionAt = _get(currentContactSequence, "data.lastActionAt")
    ? moment(_get(currentContactSequence, "data.lastActionAt"), moment.ISO_8601)
    : moment(_get(currentContactSequence, "data.createdAt"), moment.ISO_8601);
  $: nextActionAndWhen = computeNextNonDelayActionAndWhen(
    _map(_get(currentContactSequence, "data.nextActions", []), "node"),
    lastActionAt
  );
  $: nextActionFromNow = _get(nextActionAndWhen, "when")
    ? nextActionAndWhen.when.clone().subtract(3, "minutes").isBefore()
      ? "in a few mins"
      : nextActionAndWhen.when.fromNow()
    : null;
  $: nextActionDisplay =
    !_get(currentContactSequence, "data.isPaused") &&
    !_get(currentContactSequence, "data.isCompleted") &&
    _get(nextActionAndWhen, "nextAction")
      ? `Next ${(
          nextActionAndWhen.nextAction.type || "event"
        ).toLowerCase()} ${nextActionFromNow || ""}`
      : null;

  const getProfile = (contact, where) => {
    let value = _get(
      _find(_get(contact, "data.uids", []), {
        data: { contactField: { data: where } },
      }),
      "data.value"
    );
    if (value) {
      return value;
    }
    let valueJson = _get(
      _find(_get(contact, "data.profile", []), {
        data: { contactField: { data: where } },
      }),
      "data.valueJson"
    );
    if (valueJson) {
      return JSON.parse(valueJson);
    }
  };

  $: profileFirstName = getProfile(contactData, { isFirstName: true }) || "";
  $: profileLastName = getProfile(contactData, { isLastName: true }) || "";
  $: nameDisplay = _trim(`${profileFirstName} ${profileLastName}`);

  $: profileEmail = getProfile(contactData, { isEmail: true }) || "";

  $: profileSms = getProfile(contactData, { isSms: true }) || "";
  $: phoneNumber =
    parsePhoneNumberFromString(profileSms) ||
    parsePhoneNumberFromString("+1" + profileSms);
  $: phoneNumberDisplay = phoneNumber ? phoneNumber.formatNational() : "";
  $: phoneNumberUri = phoneNumber ? phoneNumber.getURI() : "";

  $: sequenceName = _get(currentContactSequence, "data.sequence.data.name", "");

  // function handleCheckboxChange(event) {
  //   selectContacts(contactData.ref, event.target.checked);
  // }

  async function handleClickRetrieveButton(contactId) {
    try {
      const response = await retrieveContactMutation({
        variables: {
          contactId: contactId,
        },
      });
      let tempContactData = contactData;
      tempContactData.data.isDeleted = false;
      tempContactData.data.deletedAt = null;
      contactData = tempContactData;
    } catch (error) {
      console.log(error);
      alert(error);
    }
  }
</script>

<div class="row">
  <div class="contact-status">
    <ContactStatusDropdown
      bind:open={isContactStatusDropdownOpen}
      {contactData}
    />
  </div>
  {#if !contactData.data.isDeleted}
    <a href="{contactPath}/sequences" class="contact-link">
      <span class="name">
        {nameDisplay || "unknown"}
      </span>
      <span class="email-phone">
        {profileEmail || ""}
        {#if profileEmail && phoneNumberDisplay}
          •
        {/if}
        {phoneNumberDisplay || ""}
      </span>
    </a>
    <div class="schedule-info">
      <div class="sequence-status">
        {#if currentContactSequence}
          {#if _get(currentContactSequence, "data.isCompleted")}
            <ion-icon name="checkmark-done" />
          {:else if _get(currentContactSequence, "data.isPaused")}
            <ion-icon name="pause" />
          {:else}
            <ion-icon name="play" class="active" />
          {/if}
          <span title={sequenceName || ""}>
            {sequenceName || "Untitled Sequence"}
          </span>
        {:else}
          <ion-icon name="stop" />
          <!-- <ion-icon name="close" /> -->
          No Action Sequence
        {/if}
      </div>
      <div class="next-scheduled-event">
        {#if nextActionDisplay}
          <!-- <ion-icon name="time-outline" /> -->
          <span title={nextActionDisplay}>
            {nextActionDisplay}
          </span>
        {/if}
      </div>
    </div>
  {:else}
    <a href="{contactPath}/sequences" class="contact-link deleted-contact">
      <span class="name">
        {nameDisplay || "unknown"}
      </span>
      <span class="email-phone">
        {profileEmail || ""}
        {#if profileEmail && phoneNumberDisplay}
          •
        {/if}
        {phoneNumberDisplay || ""}
      </span>
    </a>
    <div class="deleted-info">
      <DeleteStatusBox deletedAt={contactData.data.deletedAt} />
      <button
        type="button"
        on:click|stopPropagation={() =>
          handleClickRetrieveButton(contactData.ref)}
        class="button"
      >
        <ion-icon name="reload-outline" class="btn-icon" />
        <span class="btn-text"> Restore </span>
      </button>
    </div>
  {/if}
</div>

<style>
  .row {
    /* border-top: 1px solid var(--offwhite); */
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }
  .row > * {
    padding-left: 10px;
    padding-right: 10px;
  }
  .row > :first-child {
    padding-left: 15px;
  }
  .row > :last-child {
    padding-right: 15px;
  }

  .contact-status {
    flex-grow: 0;
    flex-shrink: 0;
    width: 140px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .contact-link {
    flex-grow: 1;
    flex-shrink: 0;
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .schedule-info {
    flex-grow: 0;
    flex-shrink: 0;
    width: 220px;
    padding: 15px 0;
    font-size: 14px;
    line-height: 20px;
  }
  .name {
    display: block;
    font-weight: 600;
  }
  .contact-link:hover .name {
    text-decoration: underline;
  }
  .email-phone {
    display: block;
    opacity: 0.6;
    font-weight: 400;
  }
  .sequence-status {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* background: var(--offwhite); */
    /* border-radius: 20px; */
    /* padding: 0 5px; */
    margin: 0;
    font-weight: 600;
  }
  .sequence-status ion-icon {
    font-size: 16px;
    color: var(--offblack);
    position: relative;
    top: 3px;
  }
  .sequence-status ion-icon.active {
    color: var(--blue);
  }
  .next-scheduled-event {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0.6;
    font-weight: 400;
  }
  .deleted-contact {
    text-decoration: line-through;
  }
  .deleted-info {
    display: flex;
    gap: 20px;
    align-items: center;
  }
</style>
