// Can't import @auth0/auth0-spa-js due to conflict between their browser entrypoint and terser.
// import createAuth0Client from '@auth0/auth0-spa-js/dist/lib/auth0-spa-js.cjs.js';
import createAuth0Client from "@auth0/auth0-spa-js/dist/auth0-spa-js.development.js";
import _forEach from "lodash/forEach";
import _get from "lodash/get";
import { navigate } from "svelte-routing";

const auth0Promise = (async function () {
  const auth0 = await createAuth0Client({
    domain: __AUTH0_DOMAIN__,
    client_id: __AUTH0_CLIENT_ID__,
    audience: __AUTH0_AUDIENCE__,
    advancedOptions: {
      defaultScope: "profile email", // change the scopes that are applied to every auth0 request. **Note**: `openid` is always specified regardless of this setting
    },
  });

  // try {
  //     await auth0.getTokenSilently();
  // } catch (error) {
  //     if (error.error !== 'login_required') {
  //         throw error;
  //     }
  // }

  let isAuthenticated = await auth0.isAuthenticated();
  // console.log(isAuthenticated ? 'yes authenticated' : 'not authenticated')

  const query = window.location.search;
  if (/code=/.test(query) && /state=/.test(query)) {
    // console.log('detected login return params');
    let loginData;
    if (!isAuthenticated) {
      try {
        // Process the login state
        loginData = await auth0.handleRedirectCallback();
      } catch (error) {
        console.log(error);
      }
    }
    let dest = _get(loginData, "appState.path", "/");
    if (dest == "/login") {
      dest = "/";
    }
    // console.log('navigate to', dest);
    // window.location = dest;
    // await auth0.getTokenSilently();
    navigate(dest);
  } else if (!isAuthenticated) {
    // console.log('redirecting to login');
    await loginOrSignup({ auth0: auth0 });
  }

  return auth0;
})();

const getIdToken = async () => {
  const auth0 = await auth0Promise;
  const token = await auth0.getTokenSilently();
  console.log("Auth0 token => ", token);
  return token;
};

// We don't need this right now since we are using Auth0 Universal Login via
// page redirects. Try to use getIdToken() instead, since the id_token won't
// change during the course of a single page load.
// const idTokenCallbacks = [];
// const onIdToken = (callback) => idTokenCallbacks.push(callback);
// auth0Promise.then((auth0) => {
//     const _save = auth0.cache.save;
//     auth0.cache.save = (data) => {
//         _save.call(auth0.cache, data);
//         _forEach(idTokenCallbacks, (callback) => callback(data.id_token));
//     };
// });

async function isAuthenticated() {
  const auth0 = await auth0Promise;
  const isAuthenticated = await auth0.isAuthenticated();
  // console.log('isAuthenticated', isAuthenticated);
  return isAuthenticated;
}

async function getUser() {
  const auth0 = await auth0Promise;
  const user = await auth0.getUser();
  // console.log('user', user);
  return user;
}

async function loginOrSignup(options) {
  // const auth0 = await auth0Promise;
  const auth0 = options && options.auth0 ? options.auth0 : await auth0Promise;
  options = options || {};
  options.path = options.path || window.location.pathname;
  let result = await auth0.loginWithRedirect({
    redirect_uri: window.location.origin + "/login/return",
    // TODO Can we send user straight to signup screen?
    // signup: !!options.signup,
    appState: {
      path: options.path,
    },
  });
  return result;
}

// async function autoLogin() {
//     const isAuthed = await isAuthenticated();
//     if (!isAuthed) {
//         // console.log('autologin!!!')
//         loginOrSignup();
//         // setTimeout(loginOrSignup, 10000);
//     }
// }
// autoLogin();

const login = (options) => loginOrSignup(options);

const signup = (options) => loginOrSignup({ ...options, signup: true });

// async function handleLoginReturn() {
//     const auth0 = await auth0Promise;
//     let result = await auth0.handleRedirectCallback();
//     return result;
// }

// const getApiToken = () => auth0Promise.then((auth0) => {
//     const differentAudienceOptions = {
// //         audience: 'https://mydomain/another-api/',
// //         scope: 'read:rules',
// //         redirect_uri: 'http://localhost:3000/callback.html'
//     };
//     return auth0.getTokenSilently(differentAudienceOptions);
// });

async function logout() {
  const auth0 = await auth0Promise;
  let result = await auth0.logout({
    returnTo: window.location.origin,
  });
  return result;
}

export {
  auth0Promise,
  getIdToken,
  // onIdToken,
  isAuthenticated,
  getUser,
  signup,
  login,
  // handleLoginReturn,
  logout,
};
