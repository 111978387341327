<script context="module">
  import gql from "graphql-tag";

  const FRAGMENTS = {};
  FRAGMENTS.sequence = gql`
    fragment sequence on Sequence {
      ref
      data {
        name
        actions {
          ref
        }
        isDeleted
        deletedAt
      }
    }
  `;

  const QUERIES = {};
  QUERIES.orgSequences = gql`
    query orgSequences($orgId: ID!) {
      findOrgByID(id: $orgId) {
        ref
        data {
          sequences {
            ...sequence
          }
        }
      }
    }
    ${FRAGMENTS.sequence}
  `;

  const MUTATIONS = {};
  MUTATIONS.createSequence = gql`
    mutation createSequence($sequence: SequenceInput!) {
      createSequence(data: $sequence) {
        ref
      }
    }
  `;
  MUTATIONS.temporarilyDeleteSequences = gql`
    mutation temporarilyDeleteSequences($sequenceIds: [ID!]) {
      temporarilyDeleteSequences(sequenceIds: $sequenceIds) {
        ref
      }
    }
  `;
  MUTATIONS.retrieveSequence = gql`
    mutation retrieveSequence($sequenceId: ID!) {
      retrieveSequence(sequenceId: $sequenceId) {
        ref
      }
    }
  `;
  export { FRAGMENTS, QUERIES, MUTATIONS };
</script>

<script>
  import { getClient, query, mutation } from "svelte-apollo";
  import { navigate } from "svelte-routing";
  import _get from "lodash/get";
  import { watchQuery } from "../../../svelte-apollo-watch-query";
  import HtmlHeadTitle from "../../../components/HtmlHeadTitle.svelte";
  import OrgPageLayout from "../../../components/OrgPageLayout.svelte";
  import Toggle from "../../../components/Toggle.svelte";
  import { formatError } from "graphql";
  import DeleteStatusBox from "../../../components/DeleteStatusBox.svelte";
  export let orgId;
  let showDeleted = true;
  const client = getClient();
  const createSequenceMutation = mutation(MUTATIONS.createSequence);
  const temporarilyDeleteSequencesMutation = mutation(
    MUTATIONS.temporarilyDeleteSequences
  );
  const retrieveSequenceMutation = mutation(MUTATIONS.retrieveSequence);

  const pageTitle = "Action Sequences";

  const orgSequencesQuery = watchQuery(client, {
    query: QUERIES.orgSequences,
    variables: { orgId: orgId },
    pollInterval: 30000,
  });
  $: totalOrgSequences = _get(
    $orgSequencesQuery,
    "data.findOrgByID.data.sequences",
    []
  );
  $: orgSequences = showDeleted
    ? totalOrgSequences
    : totalOrgSequences.filter((sequence) => !sequence.data.isDeleted);
  $: isLoading = $orgSequencesQuery.loading && !orgSequences.length;

  async function createSequence() {
    try {
      // let response = await mutate(client, {
      //     mutation: MUTATIONS.createSequence,
      //     variables: {
      //         sequence: {
      //             org: orgId,
      //             name: 'New Sequence', // FIXME better name generation
      //             pauseOnReply: true,
      //         },
      //     },
      // });
      let response = await createSequenceMutation({
        variables: {
          sequence: {
            org: orgId,
            name: "New Sequence", // FIXME better name generation
            pauseOnReply: true,
          },
        },
      });
      navigate(`/orgs/${orgId}/sequences/${response.data.createSequence.ref}`);
    } catch (error) {
      // FIXME handle errors better...
      alert(error.message);
    }
  }

  let selectedSequenceIds = [];
  let isDeletingSequences = false;

  function handleCheckboxChange(event) {
    if (event.target.checked) selectedSequenceIds.push(event.target.id);
    else
      selectedSequenceIds = selectedSequenceIds.filter(
        (id) => id != event.target.id
      );
  }

  function handleClickDeleteButton() {
    isDeletingSequences = true;
  }

  async function handleConfirmButtonClick() {
    if (selectedSequenceIds.length == 0) {
      alert("There are no sequences selected for deletion!");
      return;
    }

    if (
      confirm(
        "Temporarily deletes the selected sequences. They will be permanently removed after 30 days. Their functionality will be closed temporarily until then. Also, you cannot change the settings. Do you want to proceed?"
      )
    ) {
      try {
        const response = await temporarilyDeleteSequencesMutation({
          variables: {
            sequenceIds: selectedSequenceIds,
          },
        });
        totalOrgSequences = totalOrgSequences.map((sequence) => {
          if (selectedSequenceIds.includes(sequence.ref)) {
            const sequenceData = sequence.data;
            const now = new Date();
            const dateNow = now.toISOString().split("T")[0];
            return {
              ref: sequence.ref,
              data: {
                ...sequenceData,
                isDeleted: true,
                deletedAt: dateNow,
              },
            };
          }
          return sequence;
        });
      } catch (error) {
        console.log(error);
        alert(error);
      }
      isDeletingSequences = false;
      selectedSequenceIds = [];
    } else {
      return;
    }
  }

  function handleCancelButtonClick() {
    isDeletingSequences = false;
    selectedSequenceIds = [];
  }

  async function handleClickRetrieveButton(sequenceId) {
    try {
      const response = await retrieveSequenceMutation({
        variables: {
          sequenceId: sequenceId,
        },
      });
      totalOrgSequences = totalOrgSequences.map((sequence) => {
        if (sequence.ref == response.data.retrieveSequence.ref) {
          const sequenceData = sequence.data;
          return {
            ref: sequence.ref,
            data: {
              ...sequenceData,
              isDeleted: false,
            },
          };
        }
        return sequence;
      });
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
</script>

<HtmlHeadTitle {pageTitle} />

<OrgPageLayout {orgId} title={pageTitle}>
  <section class="card listgroup">
    <header class="listgroup-head">
      <!-- <div class="listgroup-bulkaction">
                <a>Select all</a>
            </div> -->
      <div class="listgroup-head-left">
        <span class="chip">
          {isLoading ? "loading" : orgSequences.length} sequences
        </span>
      </div>
      <div class="listgroup-head-right">
        {#if !isDeletingSequences}
          <button
            type="button"
            title="Delete"
            on:click|stopPropagation={handleClickDeleteButton}
            class="button danger"
          >
            <ion-icon name="trash" class="btn-icon" />
            <span class="btn-text"> Delete Sequences </span>
          </button>

          <button
            type="button"
            on:click|stopPropagation={createSequence}
            class="button"
          >
            <ion-icon name="add" class="btn-icon" />
            <span class="btn-text"> Create a New Sequence </span>
          </button>
        {:else}
          <button
            type="button"
            title="Delete"
            on:click|stopPropagation={handleConfirmButtonClick}
            class="button danger"
          >
            <ion-icon name="trash" class="btn-icon" />
            <span class="btn-text"> Confirm Deletion </span>
          </button>
          <button
            type="button"
            on:click|stopPropagation={handleCancelButtonClick}
            class="button cancel"
          >
            <ion-icon name="close-circle-outline" class="btn-icon" />
            <span class="btn-text"> Cancel Deletion </span>
          </button>
        {/if}
        <label class="filter-deleted">
          <span class="text">Show Deleted Seqeuences?</span>
          <Toggle bind:checked={showDeleted} />
          <!-- on:change={handleToggleChange} -->
        </label>
      </div>
    </header>
    <ul>
      {#if isLoading}
        <!-- <li>Loading Sequences...</li> -->
      {:else if $orgSequencesQuery.errors}
        <li>
          errors: <pre>{JSON.stringify(
              $orgSequencesQuery.errors,
              null,
              4
            )}</pre>
        </li>
      {:else if !orgSequences.length}
        <li>
          <div class="empty-message">
            <div class="iconcol">
              <!-- <ion-icon name="color-wand" /> -->
              <ion-icon name="flash" />
            </div>
            <div class="textcol">
              <div class="text-primary">No sequences yet!</div>
              <div class="text-secondary">
                Action Sequences allow you to automate your lead follow-up
                strategy.
              </div>
            </div>
          </div>
        </li>
      {:else}
        {#each orgSequences as sequence (sequence.ref)}
          <li>
            {#if sequence.data.isDeleted}
              <div class="row">
                <div class="row-deleted">
                  <a
                    href="/orgs/{orgId}/sequences/{sequence.ref}"
                    class="sequence-link"
                  >
                    <span class="title">
                      {#if sequence.data.name}
                        {sequence.data.name}
                      {:else}
                        (Untitled Sequence)
                      {/if}
                    </span>
                    <span class="subtitle">
                      {_get(sequence, "data.actions.length")} actions
                    </span>
                  </a>
                </div>
                <div class="deleted-info">
                  <DeleteStatusBox deletedAt={sequence.data.deletedAt} />
                  <button
                    type="button"
                    on:click|stopPropagation={() =>
                      handleClickRetrieveButton(sequence.ref)}
                    disabled={isDeletingSequences}
                    class="button"
                  >
                    <ion-icon name="reload-outline" class="btn-icon" />
                    <span class="btn-text"> Restore </span>
                  </button>
                </div>
              </div>
            {:else}
              <div class="row">
                <a
                  href="/orgs/{orgId}/sequences/{sequence.ref}"
                  class="sequence-link"
                >
                  <span class="title">
                    {#if sequence.data.name}
                      {sequence.data.name}
                    {:else}
                      (Untitled Sequence)
                    {/if}
                  </span>
                  <span class="subtitle">
                    {_get(sequence, "data.actions.length")} actions
                  </span>
                </a>
                {#if isDeletingSequences}
                  <div class="sequence-delete-checkbox">
                    <input
                      type="checkbox"
                      id={sequence.ref}
                      on:change={handleCheckboxChange}
                    />
                  </div>
                {/if}
              </div>
            {/if}
          </li>
        {/each}
      {/if}
    </ul>
  </section>
</OrgPageLayout>

<style>
  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .card {
    background: var(--white);
    border: 1px solid var(--offoffwhite);
    border-radius: 9px;
  }
  .listgroup {
    margin: 0 20px;
  }
  .listgroup-head {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
  .listgroup-head-left {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 12px 15px;
  }
  .listgroup-head-right {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 12px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .chip {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 15px;
    padding: 0 10px;
    background: var(--offwhite);
  }
  .listgroup-head-right .button {
    height: 30px;
    width: 180px;
    margin: 0 10px 0 10px;
  }
  .listgroup-head-right .button ion-icon {
    --ionicon-stroke-width: 48px;
  }
  .row {
    border-top: 1px solid var(--offwhite);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    align-items: center;
  }
  .row > * {
    padding-left: 10px;
    padding-right: 10px;
  }
  .row > :first-child {
    padding-left: 15px;
  }
  .row > :last-child {
    padding-right: 15px;
  }
  .sequence-link {
    flex-grow: 1;
    flex-shrink: 1;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .title {
    display: block;
    font-weight: 600;
  }
  .sequence-link:hover .title {
    text-decoration: underline;
  }
  .subtitle {
    display: block;
    opacity: 0.6;
    font-weight: 400;
  }
  .empty-message {
    margin: 20px;
    padding: 20px;
    background: var(--lightblue);
    color: var(--blue);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-around; */
    /* width: 100%; */
    box-sizing: border-box;
  }
  .empty-message .iconcol {
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 20px;
    font-size: 48px;
    margin-right: 10px;
  }
  .empty-message .textcol {
    flex-grow: 1;
    flex-shrink: 0;
  }
  .empty-message .text-primary {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
  }
  .empty-message .text-secondary {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    opacity: 0.8;
  }
  .button.danger {
    color: var(--red);
  }

  .button.cancel {
    color: var(--blue);
  }
  .sequence-delete-checkbox {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    width: 90px;
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    justify-content: center;
    align-items: center;
  }
  input[type="checkbox"] {
    width: 25px;
    height: 25px;
  }
  .filter-deleted {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5px;
  }
  .filter-deleted .text {
    margin-right: 6px;
  }
  .row-deleted {
    text-decoration: line-through;
  }
  .form-retrieve-button {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    width: 90px;
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    justify-content: center;
    align-items: center;
  }
  .form-retrieve-icon {
    width: 30px;
    height: 30px;
  }
  .deleted-info {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0px 20px;
  }
  .deleted-style {
    display: flex;
    flex-direction: column;
    width: 115px;
    height: 40px;
    padding: 4px 15px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 5px;
    color: #d65543;
    background: rgba(214, 67, 67, 0.12);
  }
  .status-text {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px 0px 2px 0px;
  }
  .date-text {
    text-decoration: none !important;
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  .status-icon {
    width: 15px;
    height: 15px;
    margin-right: 4px;
  }
</style>
