<script context="module">
  import gql from "graphql-tag";

  const FRAGMENTS = {};
  const QUERIES = {};
  QUERIES.getOrgBulkMessages = gql`
    query getOrgBulkMessages($orgId: ID!) {
      getOrgBulkMessages(orgId: $orgId) {
        ref
        data {
          toContacts
          fulfilled
          isScheduled
          willSendAt
          createdAt
          subject
          text
          smsViaLocal
          asSms
          asEmail
        }
      }
    }
  `;

  const MUTATIONS = {};
  MUTATIONS.deleteScheduledBulkMessage = gql`
    mutation deleteScheduledBulkMessage($id: ID!) {
      deleteScheduledBulkMessage(id: $id) {
        ref
      }
    }
  `;
  export { FRAGMENTS, QUERIES, MUTATIONS };
</script>

<script>
  import { getClient, query, mutation } from "svelte-apollo";
  import { navigate } from "svelte-routing";
  import _get from "lodash/get";
  import _isNumber from "lodash/isNumber";
  import { watchQuery } from "../../svelte-apollo-watch-query";
  import HtmlHeadTitle from "../../components/HtmlHeadTitle.svelte";
  import OrgPageLayout from "../../components/OrgPageLayout.svelte";
  import BulkMessageListItem from "../../components/BulkMessageListItem.svelte";

  export let orgId;

  const client = getClient();
  const pageTitle = "Bulk Messages";

  const deleteScheduledBulkMessageMutation = mutation(
    MUTATIONS.deleteScheduledBulkMessage
  );
  const getOrgBulkMessagesQuery = watchQuery(client, {
    query: QUERIES.getOrgBulkMessages,
    variables: { orgId: orgId },
    pollInterval: 30000,
  });
  $: getOrgBulkMessagesData = _get(
    $getOrgBulkMessagesQuery,
    "data.getOrgBulkMessages",
    []
  );
  async function handleDeleteBulkMessageClick(id) {
    const response = await deleteScheduledBulkMessageMutation({
      variables: {
        id: id,
      },
    });
  }
</script>

<HtmlHeadTitle {pageTitle} />

<OrgPageLayout {orgId} title={pageTitle}>
  <section class="card listgroup">
    <header class="listgroup-head">
      <!-- <div class="listgroup-bulkaction">
                <a>Select all</a>
            </div> -->
      <div class="listgroup-head-left">
        <span class="chip">
          {getOrgBulkMessagesData ? getOrgBulkMessagesData.length : "loading"}
          Bulk Messages
        </span>
      </div>
      <div class="listgroup-head-right">
        <a class="button navigate-page" href="messages">View Messages</a>
      </div>
    </header>
    <ul>
      {#if $getOrgBulkMessagesQuery.loading && !getOrgBulkMessagesData.length}
        <!-- <li></li> -->
      {:else if $getOrgBulkMessagesQuery.errors}
        <li>
          errors: <pre>{JSON.stringify(
              $getOrgBulkMessagesQuery.errors,
              null,
              4
            )}</pre>
        </li>
      {:else if getOrgBulkMessagesData.length}
        {#each getOrgBulkMessagesData as bulkMessageData (_get(bulkMessageData, "ref"))}
          <li>
            <BulkMessageListItem
              {bulkMessageData}
              {handleDeleteBulkMessageClick}
            />
            <!-- <ConversationListItem conversationData={conversation} /> -->
          </li>
        {/each}
      {:else}
        <li>
          <div class="empty-message">
            <div class="iconcol">
              <ion-icon name="chatbubble-ellipses" />
            </div>
            <div class="textcol">
              <div class="text-primary">No bulk messages yet!</div>
              <div class="text-secondary">
                To start a new bulk message, start from your
                <a href={`/orgs/${orgId}/contacts`}>Contacts list</a>.
              </div>
            </div>
          </div>
        </li>
      {/if}
      <!-- <li><a href="create-contact" on:click|preventDefault={createForm}>Create a New Form</a></li> -->
    </ul>
  </section>
</OrgPageLayout>

<style>
  .listgroup {
    margin: 0 20px;
  }
  .listgroup-head,
  .listgroup li {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
  .listgroup-head-left {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 12px 15px;
  }
  .listgroup-head-right {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 12px 15px;
  }
  .chip {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 15px;
    padding: 0 10px;
    background: var(--offwhite);
  }
  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .card {
    background: var(--white);
    border: 1px solid var(--offoffwhite);
    border-radius: 9px;
  }
  .empty-message {
    margin: 20px;
    padding: 20px;
    background: var(--lightblue);
    color: var(--blue);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-around; */
    width: 100%;
    box-sizing: border-box;
  }
  .empty-message .iconcol {
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 20px;
    font-size: 48px;
    margin-right: 10px;
  }
  .empty-message .textcol {
    flex-grow: 1;
    flex-shrink: 0;
  }
  .empty-message .text-primary {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
  }
  .empty-message .text-secondary {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    opacity: 0.8;
  }
  .navigate-page {
    font-size: 14px;
    height: 30px;
    padding: 0px 30px;
  }
</style>
