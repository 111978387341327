import { fetchWithAuth } from "./apollo-client-app";

function requestOrgSmsNumber(orgId, requestDetails) {
  return fetchWithAuth(`/api/orgs/${orgId}/request-sms-number`, {
    method: "POST",
    mode: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestDetails),
  });
}

function removeOrgSmsNumber(orgId, requestDetails) {
  return fetchWithAuth(`/api/orgs/${orgId}/remove-sms-number`, {
    method: "POST",
    mode: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestDetails),
  });
}

function createMessageRequest(data) {
  return fetchWithAuth(`/api/message-requests/new`, {
    method: "POST",
    mode: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

function sendBulkMessages(data) {
  return fetchWithAuth(`/api/message-requests/bulk-message`, {
    method: "POST",
    mode: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

function createStripeCheckoutSessionForNewSubscription(orgId, stripePriceId) {
  return fetchWithAuth(`/api/stripe/create-checkout-session`, {
    method: "POST",
    mode: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      orgId: orgId,
      stripePriceId: stripePriceId,
    }),
  });
}

function createStripePortalSessionForExistingSubscription(
  orgId,
  stripePriceId
) {
  return fetchWithAuth(`/api/stripe/create-portal-session`, {
    method: "POST",
    mode: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      orgId: orgId,
    }),
  });
}

function updateOrgStripeSubscriptions(orgId) {
  return fetchWithAuth(`/api/orgs/${orgId}/update-stripe-subscriptions`, {
    method: "POST",
    mode: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function rechargeSms(orgId) {
  return fetchWithAuth(`/api/stripe/recharge?orgId=${orgId}`, {
    method: "POST",
    mode: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  });
}
function getAllOrgs(orgId, currentPage, activeTag, searchText) {
  return fetchWithAuth(
    `/api/admin/get-orgs?tag=${activeTag}&currentPage=${currentPage}&searchText=${searchText}`,
    {
      method: "GET",
      mode: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
function brandRegistrationOfOrg(orgId, data) {
  return fetchWithAuth(`/api/orgs/${orgId}/brand-registration`, {
    method: "POST",
    mode: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      data,
    }),
  });
}
function campaignRegistrationOfOrg(orgId, data) {
  return fetchWithAuth(`/api/orgs/${orgId}/campaign-registration`, {
    method: "POST",
    mode: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      data,
    }),
  });
}

function updateOrgsDeletionStatus(orgIds, orgNames, deletionStatus) {
  return fetchWithAuth(`/api/orgs/update-orgs-deletion-status`, {
    method: "POST",
    mode: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      orgIds: orgIds,
      orgNames: orgNames,
      deletionStatus: deletionStatus,
    }),
  });
}

function updateCallForwardSetting(orgId, forwardingNumber, callForwardStatus) {
  return fetchWithAuth(`/api/orgs/${orgId}/call-forward`, {
    method: "POST",
    mode: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      callForwardStatus: callForwardStatus,
      forwardingNumber: forwardingNumber,
    }),
  });
}

function sendCallForwardingVerificationCode(
  orgId,
  forwardingNumber,
  verificationCode
) {
  return fetchWithAuth(
    `/api/orgs/${orgId}/send-call-forwarding-verificaiton-code`,
    {
      method: "POST",
      mode: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        verificationCode: verificationCode,
        toNumber: forwardingNumber,
      }),
    }
  );
}

function newAccountCreationDetails(orgId) {
  return fetchWithAuth(`/api/orgs/${orgId}/new-account-creation-details`, {
    method: "POST",
    mode: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function exportContactsInfoAsCSV(orgId, orgName) {
  return fetchWithAuth(`/api/admin/${orgId}/export-org-contacts-as-csv`, {
    method: "GET",
    mode: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${orgName}-Contacts.csv`); // Customize filename
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      return "OK";
    })
    .catch((error) => console.error("Download failed", error));
}
export {
  requestOrgSmsNumber,
  removeOrgSmsNumber,
  createMessageRequest,
  sendBulkMessages,
  createStripeCheckoutSessionForNewSubscription,
  createStripePortalSessionForExistingSubscription,
  updateOrgStripeSubscriptions,
  rechargeSms,
  getAllOrgs,
  brandRegistrationOfOrg,
  campaignRegistrationOfOrg,
  updateOrgsDeletionStatus,
  updateCallForwardSetting,
  sendCallForwardingVerificationCode,
  newAccountCreationDetails,
  exportContactsInfoAsCSV,
};
